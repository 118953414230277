import { httpClient } from "@common/Libs/axios";
import {
  AccountReportFormValues,
  AccountReportsType,
  DownloadReportParams,
  PartialFiltersType,
  PaymentReportType,
  ProviderType,
  QueryParams,
  RefundReportsType,
  ReportType,
} from "@common/Types";
import {
  buildCommonFiltersValues,
  buildDateRangeFiltersValues,
  commaSerializer,
} from "@common/Utils";

import { reportsPath } from "./path";

export const reportsService = {
  getReports: (filters: PartialFiltersType) =>
    httpClient.get<ReportType>(reportsPath.dashboard_reports, {
      params: {
        ...buildCommonFiltersValues(filters),
      },
      paramsSerializer: commaSerializer,
    }),

  getPaymentReports: (filters: PartialFiltersType) =>
    httpClient.get<PaymentReportType>(reportsPath.reports_payment, {
      params: {
        ...buildCommonFiltersValues(filters),
      },
      paramsSerializer: commaSerializer,
    }),

  getPaymentProviders: () =>
    httpClient.get<ProviderType[]>(reportsPath.reports_payment_providers),

  getRefundReports: ({ skip, limit, filters }: QueryParams) =>
    httpClient.get<RefundReportsType>(reportsPath.reports_refund_history, {
      params: {
        ...buildCommonFiltersValues(filters),
        skip,
        limit,
      },
      paramsSerializer: commaSerializer,
    }),

  getAccountReports: ({ skip, limit, filters }: QueryParams) =>
    httpClient.get<AccountReportsType[]>(reportsPath.reports, {
      params: {
        ...buildDateRangeFiltersValues(filters),
        skip,
        limit,
      },
      paramsSerializer: commaSerializer,
    }),

  downloadAccountReport: ({ reportId, type }: DownloadReportParams) =>
    httpClient.get<{ url: string }>(
      reportsPath.reports_account_reports_download(reportId),
      {
        params: {
          report_file_type: type,
        },
      },
    ),

  createAccountReport: (report: AccountReportFormValues) =>
    httpClient.post(reportsPath.reports, report),
};
