import { httpClient } from "@common/Libs/axios";
import { RidersParams, RidersType } from "@common/Types";
import { commaSerializer, removeEmptyFilterValues } from "@common/Utils";

import { ridersPath } from "./path";

/**
 * Описание методов riders.
 */

export const ridersService = {
  getRiders: ({ limit, skip, filters }: RidersParams) => {
    const buildFilter = removeEmptyFilterValues(filters);

    return httpClient.get<RidersType>(ridersPath.riders, {
      params: { limit, skip, ...buildFilter },
      paramsSerializer: commaSerializer,
    });
  },
  cleanRidersDebt: (rider_id: string) =>
    httpClient.post(ridersPath.clean_riders_debt(rider_id), {}),
};
