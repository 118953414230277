import { Field } from "formik";
import React from "react";

import { FilterTabType } from "@common/Types";

type Props = {
  dir?: "row" | "col";
  label: string;
  field: string;
  array: FilterTabType[] | [];
  error?: string | string[];
  touched?: boolean;
  required?: boolean;
};

export const FormCheckBox: React.FC<Props> = ({
  dir = "row",
  label,
  field,
  array,
  error,
  touched,
  required,
}) => {
  return (
    <div className="flex flex-col items-start gap-3">
      <label className="text-neutral-text-800 text-caption-b font-medium pb-[4px] flex flex-row">
        {label}
        {required && <span className="text-error ml-1"> *</span>}
      </label>
      <div
        className={`flex flex-${dir} items-${
          dir === "row" ? "center" : "start"
        } flex-wrap gap-4`}
      >
        {array &&
          array.map((tariff) => (
            <label
              className="flex items-center cursor-pointer"
              key={tariff.value}
            >
              <Field
                type="checkbox"
                name={field}
                value={tariff.value}
                className="mr-2 cursor-pointer rounded outline-none focus:ring-1 focus:ring-blue-600 focus:ring-offset-0"
              />
              {tariff.label}
            </label>
          ))}
      </div>
      {error && touched && <p className="text-red-600 text-sm mt-1">{error}</p>}
    </div>
  );
};
