import { httpClient } from "@common/Libs/axios";
import { TariffType } from "@common/Types";
import { commaSerializer } from "@common/Utils";

import { tariffsPath } from "./paths";

/**
 * Описание методов tariffs.
 */

export const tariffsService = {
  getTariffs: () => httpClient.get<TariffType[]>(tariffsPath.tariffs),
  getTariffsById: (id: string | number) =>
    httpClient.get<TariffType[]>(tariffsPath.tariffsById, {
      params: {
        city_id: id,
      },
      paramsSerializer: commaSerializer,
    }),
};
