import React from "react";

import { QuestionType } from "@common/Types";

type Props = {
  data: QuestionType[];
  onClick: (path: string) => void;
};

export const Question = ({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) => {
  return (
    <div
      role="button"
      onClick={onClick}
      className={
        "relative w-full h-[64px] flex items-center py-[14px] px-[16px] rounded-[12px] bg-white shadow-md"
      }
    >
      <div className={"flex"}>
        <span className={"text-[16px] leading-[1.2] font-normal"}>{text}</span>
      </div>
      <div
        className={
          "absolute right-0 flex items-center justify-center rounded-[50%] bg-brand-bg-700 py-[8px] pl-[11px] pr-[9px] mr-[16px]"
        }
      >
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 9L5 5L1 1"
            stroke="#F5F8FE"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

const FAQQuestions = ({ data, onClick }: Props) => {
  return (
    <div className={"w-full flex flex-col pl-[16px] pr-[8px] gap-[10px]"}>
      {data.map((item) => {
        return (
          <Question
            key={item.path}
            text={item.question}
            onClick={() => onClick(item.path)}
          />
        );
      })}
    </div>
  );
};

export default FAQQuestions;
