import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { newsService } from "@api/services";

export const useDeleteNews = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, type }: { id: number; type: string }) =>
      newsService.deleteNews(id, type),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.NEWS] });
    },
  });
};
