import { useMutation } from "@tanstack/react-query";

import { reportsService } from "@api/services";
import { DownloadReportParams } from "@common/Types";
import { parseFilenameFromContentDisposition } from "@common/Utils";

export const useDownloadAccountReport = () => {
  return useMutation({
    mutationFn: (params: DownloadReportParams) =>
      reportsService.downloadAccountReport(params),

    onSuccess: (data, { fileName }) => {
      const contentDisposition = new URL(data.url).searchParams.get(
        "response-content-disposition",
      );

      const saveFileName = parseFilenameFromContentDisposition(
        contentDisposition,
        fileName,
      );

      const link = document.createElement("a");
      link.href = data.url;
      link.setAttribute("download", saveFileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  });
};
