import React from "react";

import { ROLES_NAVI } from "@common/Constants";
import { AvailableFilterKeys, FilterComponentPropsType } from "@common/Types";
import {
  AudienceFilter,
  AuthRender,
  CitiesFilterMobile,
  DateRangeFilter,
  FraudControlTypeFilters,
  LanguageFilterMobile,
  SubscribersFilter,
} from "@components/Shared";
import { StatusControlTypeFilters } from "@components/Shared/mobile-filters/application-status";

type FilterObjectType = {
  [key in AvailableFilterKeys]: {
    component: (props: FilterComponentPropsType) => React.JSX.Element;
  };
};

export const filtersSchema: Partial<FilterObjectType> = {
  city_id: {
    component: (props: FilterComponentPropsType) => (
      <AuthRender roles={[ROLES_NAVI.SUPERADMIN]}>
        <CitiesFilterMobile {...props} />
      </AuthRender>
    ),
  },
  fraud_status: {
    component: (props: FilterComponentPropsType) => (
      <FraudControlTypeFilters {...props} />
    ),
  },
  application_status: {
    component: ({ tabs, ...resProps }: FilterComponentPropsType) => (
      <StatusControlTypeFilters {...resProps} tabs={tabs!} />
    ),
  },
  landing_application_status: {
    component: ({ tabs, ...resProps }: FilterComponentPropsType) => (
      <StatusControlTypeFilters {...resProps} tabs={tabs!} />
    ),
  },
  is_read: {
    component: ({ tabs, ...resProps }: FilterComponentPropsType) => (
      <StatusControlTypeFilters {...resProps} tabs={tabs!} />
    ),
  },
  date_range: {
    component: (props: FilterComponentPropsType) => (
      <DateRangeFilter {...props} />
    ),
  },
  audience: {
    component: (props: FilterComponentPropsType) => (
      <AudienceFilter {...props} />
    ),
  },
  subscribers: {
    component: (props: FilterComponentPropsType) => (
      <SubscribersFilter {...props} />
    ),
  },
  language: {
    component: (props: FilterComponentPropsType) => (
      <LanguageFilterMobile {...props} />
    ),
  },
};
