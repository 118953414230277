import React from "react";

import { useDimension } from "@common/Hooks";
import { ActiveFilterType, PartialFiltersType } from "@common/Types";
import { MobileFilters } from "@components/Shared";

import { DesktopFilters } from "../desktop-filters";

type Props = {
  filters: PartialFiltersType;
  setFilters: (values: PartialFiltersType) => void;
  activeFilters: ActiveFilterType[];
};

export const GeneralFilters = ({
  filters,
  setFilters,
  activeFilters,
}: Props) => {
  const { isDesktop } = useDimension();

  const handleUpdateFilter = (filterValue: PartialFiltersType) => {
    setFilters(filterValue);
  };

  return (
    <div className="w-full flex items-center flex-wrap">
      {isDesktop ? (
        <DesktopFilters
          filters={filters}
          setFilters={handleUpdateFilter}
          activeFilters={activeFilters}
        />
      ) : (
        <MobileFilters
          filters={filters}
          setFilters={handleUpdateFilter}
          activeFilters={activeFilters}
        />
      )}
    </div>
  );
};
