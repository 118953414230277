import { Checkbox } from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import dayjs from "dayjs";
import { useField } from "formik";
import { FieldMetaProps } from "formik/dist/types";
import { isArray } from "radash";
import React, { FC } from "react";

import { isTargetNavi } from "@assets/Flags";
import { CalendarIcon } from "@assets/Icons";
import { PROMOTION_TYPES } from "@common/Types";
import { isDateObjectRange } from "@common/Utils";

type Props = {
  name: string;
  label: string;
  placeholder: string;
  withIndefinite?: boolean;
  terms?: string;
};

const excludeDate = (dateForCheck: Date): boolean => {
  const dateToCheck = dayjs(dateForCheck);
  const difference = dayjs().diff(dateToCheck, "day");
  return difference > 0;
};

type DateRangeType = [Date | null, Date | null];

const getErrorMessage = (meta: FieldMetaProps<any>) => {
  if (isArray(meta.error)) {
    return meta.error[0];
  }

  return meta.error;
};

const hasError = (meta: FieldMetaProps<any>) => {
  return meta.touched && meta.error;
};

export const RangePicker: FC<Props> = ({
  name,
  label,
  placeholder,
  withIndefinite,
  terms,
}) => {
  const currentDateString = React.useRef(new Date().toDateString());
  const [calendarField, calendarMeta, calendarHelpers] = useField(name);
  const [dailyField, dailyMeta, dailyHelpers] = useField("is_daily_updated");
  const [indefinteField, indefinteMeta, indefinteHelpers] =
    useField("indefinite");

  const isHasError = hasError(calendarMeta);

  const onChangeDates = (range: DateRangeType) => {
    if (!isDateObjectRange(range)) return;
    calendarHelpers.setValue([...range]);
  };

  return (
    <div className={`w-full relative flex flex-col space-y-1 `}>
      <label className="ext-neutral-text-800 text-caption-b font-medium">
        {label}
      </label>
      <div className={"w-full flex flex-col"}>
        <DateRangePicker
          value={calendarField.value}
          onChange={onChangeDates}
          placeholder={placeholder}
          nextMonthLabel="Следующий месяц"
          previousMonthLabel="Предыдущий месяц"
          classNames={{
            input: `h-[42px] text-body-1-a rounded-[4px] border-neutral-border-300 px-[14px] pl-[52px] ${
              isHasError ? "border-negative-bg-500" : ""
            }`,
            icon: "w-[24px] ml-[14px]",
            firstInRange: "!bg-brand-bg-600",
            lastInRange: "!bg-brand-bg-600",
            selected: "!bg-brand-bg-600",
          }}
          excludeDate={excludeDate}
          dayStyle={(date) =>
            date.toDateString() === currentDateString.current
              ? {
                  backgroundColor: isTargetNavi ? "#2855CB" : "#2BB5C8",
                  color: "white",
                }
              : {}
          }
          amountOfMonths={2}
          locale={"ru"}
          icon={<CalendarIcon />}
          onDropdownClose={() => {
            setTimeout(() => {
              calendarHelpers.setTouched(true);
            }, 50);
          }}
          disabled={indefinteField.value}
          allowSingleDateInRange
        />
        {withIndefinite && (
          <div className={"flex mt-[5px]"}>
            <Checkbox
              color={"#119BAF"}
              classNames={{
                inner: "p-[1px]",
                label:
                  "pl-[4px] text-caption-a text-neutral-text-600 font-medium",
                input:
                  "w-[14px] h-[14px] border-neutral-border-300 checked:bg-brand-bg-700 checked:border-brand-bg-700",
              }}
              label={`${
                terms === PROMOTION_TYPES.RIDE
                  ? "Ежедневная акция"
                  : "Без ограничения"
              }`}
              size="xs"
              checked={
                terms === PROMOTION_TYPES.RIDE
                  ? dailyField.value
                  : indefinteField.value
              }
              onChange={(event) => {
                terms === PROMOTION_TYPES.RIDE
                  ? dailyHelpers.setValue(event.currentTarget.checked)
                  : indefinteHelpers.setValue(event.currentTarget.checked);
              }}
              onBlur={() => {
                indefinteHelpers.setTouched(true);
              }}
            />
          </div>
        )}
        {isHasError ? (
          <p className="text-negative-bg-500 text-caption-a mt-[5px]">
            {getErrorMessage(calendarMeta)}
          </p>
        ) : null}
      </div>
    </div>
  );
};
