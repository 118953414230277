import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { settingsService } from "@api/services/settings";
import { FeesType } from "@common/Types";

type Params = {
  cityId: string;
  enabled?: boolean;
};

export const useGetCityFees = ({ cityId, enabled = true }: Params) => {
  return useQuery<FeesType>({
    queryKey: [QUERY_KEYS.CITY_FEES, cityId],
    queryFn: async () => await settingsService.getCityFees(cityId),
    enabled,
  });
};
