import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

ReactGA.initialize("G-X8XF30N3MN");

export const GoogleAnalyticsProvider = ({ children }: Props) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return <>{children}</>;
};
