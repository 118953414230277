import { httpClient } from "@common/Libs/axios";
import {
  ApplicationDetailType,
  DriverApplicationCreateUpdateType,
  DriverApplicationDeclineType,
  DriverApplicationRequest,
  DriverApplicationResponse,
  DriverApplicationResponseType,
  LandingDriverApplicationRequest,
  LandingDriverApplicationResponse,
} from "@common/Types";
import {
  buildCommonFiltersValues,
  commaSerializer,
  removeEmptyFilterValues,
} from "@common/Utils";

import { applicationsPaths } from "./paths";

/**
 * Описание методов applications.
 */

export const applicationService = {
  getDriverApplications: ({
    filters,
    skip,
    limit,
  }: DriverApplicationRequest) => {
    const buildFilter = buildCommonFiltersValues(filters);

    return httpClient.get<DriverApplicationResponse>(applicationsPaths.driver, {
      params: { ...buildFilter, skip, limit },
      paramsSerializer: commaSerializer,
    });
  },
  getDriverApplicationDetail: (id: string) => {
    return httpClient.get<ApplicationDetailType>(
      applicationsPaths.driverDetail(id),
    );
  },

  approveDriverApplication: (
    id: string,
    data: DriverApplicationCreateUpdateType,
  ) => {
    return httpClient.put<
      DriverApplicationResponseType,
      DriverApplicationCreateUpdateType
    >(applicationsPaths.driverApprove(id), data);
  },

  declineDriverApplication: (
    id: string,
    data: DriverApplicationDeclineType,
  ) => {
    return httpClient.put<void, DriverApplicationDeclineType>(
      applicationsPaths.driverDecline(id),
      data,
    );
  },

  // landing applications
  getLandingDriverApplications: ({
    filters,
    skip,
    limit,
  }: LandingDriverApplicationRequest) => {
    const buildFilter = removeEmptyFilterValues(filters);

    return httpClient.get<LandingDriverApplicationResponse>(
      applicationsPaths.landingDriverApplication,
      {
        params: { ...buildFilter, skip, limit },
      },
    );
  },

  approveLandingDriverApplications: (id: string) => {
    return httpClient.patch<void, undefined>(
      applicationsPaths.landingDriverApplicationApprove(id),
      undefined,
    );
  },
};
