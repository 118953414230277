import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import toast from "react-hot-toast";

import { ridersService } from "@api/services";
import { showErrorToast } from "@common/Utils";

export const useCleanRidersDebt = () => {
  return useMutation({
    mutationFn: (rider_id: string) => ridersService.cleanRidersDebt(rider_id),
    onSuccess: () => {
      toast.success("Долг был успешно списан");
    },
    onError: (error) => {
      showErrorToast(error as AxiosError);
    },
  });
};
