import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { permissionsService } from "@api/services";
import { AccessPages } from "@common/Types";

export const usePostAdminAccess = () => {
  return useMutation({
    mutationFn: async (accessList: AccessPages) =>
      await permissionsService.postAccess(accessList),

    onSuccess: () => toast.success("Доступ успешно обновлeн"),
  });
};
