import { RichTextEditor } from "@mantine/rte";
import React, { FC } from "react";

type Props = {
  setFieldValue: (values: string) => void;
  error?: string;
  touched?: boolean;
  value: string;
};

export const TextEditor: FC<Props> = ({
  setFieldValue,
  value,
  error,
  touched,
}) => {
  return (
    <div className="mb-10">
      <div className="bg-gray-100 border-neutral-border-300">
        <RichTextEditor
          controls={[
            ["bold", "italic", "underline", "strike", "clean"],
            ["h1", "h2", "h3", "h4"],
            ["link"],
          ]}
          value={value}
          style={{ minHeight: 200 }}
          onChange={(text) => setFieldValue(text)}
        />
      </div>
      {error && touched ? (
        <p className="text-red-600 text-sm">{error}</p>
      ) : null}
    </div>
  );
};
