/**
 * Сервисы для news
 */

import { appConfig } from "@api/config";

const src = appConfig.api.dashboard;

export const newsPath = {
  news: `${src}/news/`,
  newsDetail: (newsId?: number | null) => `${src}/news/${newsId}/`,
};
