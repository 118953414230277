import { Switch as HeadlessSwitch } from "@headlessui/react";
import React from "react";

type Props = {
  value: number | string | boolean | null;
  label?: string;
  disabled?: boolean;
  onChange: (value: boolean) => void;
};

export const Switch: React.FC<Props> = ({
  value,
  label,
  disabled,
  onChange,
}) => {
  const parsedValue = !!value;

  return (
    <div className={"flex flex-col"}>
      <HeadlessSwitch.Group>
        <div className={"flex flex-col gap-[4px]"}>
          {label && (
            <HeadlessSwitch.Label
              className={"text-neutral-800 text-caption-b font-medium"}
            >
              {label}
            </HeadlessSwitch.Label>
          )}

          <HeadlessSwitch
            checked={parsedValue}
            disabled={disabled}
            onChange={onChange}
            name={"is_default"}
            className={`${
              parsedValue ? "bg-brand-bg-500" : "bg-gray-200"
            } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500`}
          >
            <span
              className={`${
                parsedValue ? "translate-x-6" : "translate-x-1"
              } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
            />
          </HeadlessSwitch>
        </div>
      </HeadlessSwitch.Group>
    </div>
  );
};
