import React, { lazy } from "react";

import { isTargetNavi } from "@assets/Flags";

const AppLoader = lazy(() => {
  return isTargetNavi ? import("./navi") : import("./motor");
});

export const AppRouter: React.FC = () => {
  return <AppLoader />;
};
