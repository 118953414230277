import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { usersService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { UserCreateType } from "@common/Types";

export const useCreateUser = () => {
  return useMutation({
    mutationFn: (data: UserCreateType) => usersService.createUser(data),
    onSuccess: ({
      first_name = "",
      last_name = "",
      second_name,
    }: UserCreateType) => {
      const name = `${first_name} ${last_name} ${second_name ?? ""}`;
      toast.success(`Пользователь ${name} успешно зарегистрирован`);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DASHBOARD_USERS] });
    },
  });
};
