import React from "react";

import { SVGProps } from "@common/Types";

export const TooltipIcon = ({ className }: SVGProps) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6 12C2.686 12 0 9.314 0 6C0 2.686 2.686 0 6 0C9.314 0 12 2.686 12 6C12 9.314 9.314 12 6 12Z"
        fill="#333333"
      />
      <path
        d="M6.00008 6.83332V6.66665C6.00008 6.12198 6.33675 5.82665 6.67408 5.59998C7.00341 5.37798 7.33341 5.08865 7.33341 4.55532C7.33341 3.81865 6.73675 3.22198 6.00008 3.22198C5.26341 3.22198 4.66675 3.81865 4.66675 4.55532M6.00008 8.66665V8.73331"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
