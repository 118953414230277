import { Group, Text } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { isEmpty } from "radash";
import React, { useState } from "react";

import { useUploadImage } from "@api/queries";

type Props = {
  setFieldValue: (field: string, values: any) => void;
  image?: string;
  error?: string | undefined;
  touched?: boolean | undefined;
  edit?: string;
  tariff?: string;
  label?: boolean;
  onUploadStart: () => void;
  onUploadComplete: () => void;
};

export const ImageUpload = ({
  setFieldValue,
  error,
  touched,
  image,
  edit,
  tariff,
  label = false,
  onUploadStart,
  onUploadComplete,
}: Props) => {
  const [preview, setPreview] = useState<any>();
  const { mutate } = useUploadImage();

  const setImagePreview = (file: File) => {
    const reader = new FileReader();
    reader.onload = function () {
      const dataURL = reader.result;
      setPreview(dataURL);
    };
    reader.readAsDataURL(file);
  };

  const upload = (files: File[]) => {
    const file = files[0];
    setImagePreview(file);
    onUploadStart();
    mutate(file, {
      onSuccess: (response) => {
        setFieldValue("image_id", response?.id);
      },
    });
    onUploadComplete();
  };

  return (
    <div className="my-5">
      {label && (
        <Text
          size="md"
          weight={700}
          className="text-caption-b text-neutral-text-800 mb-[4px]"
        >
          Фото
        </Text>
      )}
      <Dropzone
        onDrop={upload}
        maxSize={0.5 * 1024 ** 2} //500kb
        accept={tariff ? ["image/png"] : IMAGE_MIME_TYPE}
      >
        {() => (
          <Group
            position="center"
            spacing="xl"
            style={{ minHeight: 180, pointerEvents: "none" }}
          >
            {edit ? (
              isEmpty(preview) ? (
                <img src={image} alt="initial_photo" />
              ) : (
                <img src={preview} alt="preview_photo" />
              )
            ) : preview ? (
              <>
                <img src={preview} alt="preview_photo" />
              </>
            ) : (
              <div className="text-center">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-auto mb-4"
                >
                  <path
                    d="M26.6667 8.66659V13.6499C26.6667 13.6499 23.35 13.6666 23.3333 13.6499V8.66659H18.3333C18.3333 8.66659 18.35 5.34992 18.3333 5.33325H23.3333V0.333252H26.6667V5.33325H31.6667V8.66659H26.6667ZM21.6667 15.3333V10.3333H16.6667V5.33325H3.33333C1.5 5.33325 0 6.83325 0 8.66659V28.6666C0 30.4999 1.5 31.9999 3.33333 31.9999H23.3333C25.1667 31.9999 26.6667 30.4999 26.6667 28.6666V15.3333H21.6667ZM3.33333 28.6666L8.33333 21.9999L11.6667 26.9999L16.6667 20.3333L23.3333 28.6666H3.33333Z"
                    fill="#AEB1BB"
                  />
                </svg>
                <Text size="xl" inline>
                  Перетащите или загрузите фото с компьютера
                </Text>
                {tariff ? (
                  <Text size="sm" color="dimmed" inline mt={7}>
                    Формат изображения должен быть .PNG и не превышать 500кб
                  </Text>
                ) : (
                  <Text size="sm" color="dimmed" inline mt={7}>
                    Размер файла не должен превышать 500kb .jpg, png, jpeg
                  </Text>
                )}
              </div>
            )}
          </Group>
        )}
      </Dropzone>
      {error && touched ? (
        <p className="text-red-600 text-sm mt-1">Загрузите фотографию</p>
      ) : null}
    </div>
  );
};
