import { Group, SegmentedControl, Title } from "@mantine/core";
import React from "react";

import { FilterTabType, FraudControlStatus } from "@common/Types";

type Props = {
  filterValue: FraudControlStatus;
  setFilterValue: (value: FraudControlStatus) => void;
  tabs: FilterTabType[];
};

export const StatusControlTypeFilters = ({
  filterValue,
  setFilterValue,
  tabs,
}: Props) => {
  return (
    <Group className="w-full" spacing={"xs"} direction="column">
      <Title order={5}>Статусы</Title>
      <div className={`inline-flex items-center w-full`}>
        <SegmentedControl
          size={"sm"}
          value={filterValue}
          className="w-full z-0"
          orientation="vertical"
          onChange={setFilterValue}
          data={tabs}
        />
      </div>
    </Group>
  );
};
