import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { usersService } from "@api/services";
import { UserPasswordType } from "@common/Types";

type Params = {
  id: string;
  data: UserPasswordType;
};

export const useEditUserPassword = () => {
  return useMutation({
    mutationFn: ({ id, data }: Params) =>
      usersService.editUserPassword(id, data),
    onSuccess: () => {
      toast.success(`Пароль успешно изменен`);
    },
  });
};
