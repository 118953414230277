import { fetcher } from "@common/Libs/axios";
import {
  AniFraudsQueryParams,
  FraudControlType,
  InitialSettingsType,
  ReasonAniFraudsQueryParams,
  VerdictAniFraudsQueryParams,
} from "@common/Types";
import { buildCommonFiltersValues } from "@common/Utils";

import { antiFraudPath } from "./paths";

/**
 * Описание методов anti fraud.
 */

export const antiFraudsService = {
  getAntiFraud: ({ filters, skip, limit }: AniFraudsQueryParams) => {
    const buildFilter = buildCommonFiltersValues(filters);

    return fetcher.get<FraudControlType>(antiFraudPath.anti_fraud, {
      params: {
        skip,
        limit,
        city_id: buildFilter.city_id,
        status: buildFilter.fraud_status,
      },
    });
  },

  getReasonAntiFraud: ({ paymentId }: ReasonAniFraudsQueryParams) => {
    return fetcher.get(antiFraudPath.anti_fraud_reason({ paymentId }));
  },

  getSettingsAntiFraud: () => {
    return fetcher.get(antiFraudPath.anti_fraud_settings);
  },

  verdictAntiFraud: ({
    blocked_payment_id,
    type,
    description,
  }: VerdictAniFraudsQueryParams) => {
    return fetcher.post(antiFraudPath.anti_fraud, {
      blocked_payment_id,
      set_to_status: type,
      description,
    });
  },

  updateSettingsAntiFraud: ({
    amount_limit,
    time_limit,
  }: InitialSettingsType) => {
    return fetcher.patch(antiFraudPath.anti_fraud_settings, {
      amount_limit,
      time_limit,
    });
  },
};
