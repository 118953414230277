import React from "react";

export const TaxiParkIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3337 7.5L16.1504 8.33333"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.85866 8.33333L1.66699 7.5"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2083 11.6667H13.125"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.87533 11.6667H4.79199"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1498 8.33325L15.4506 4.98992C15.2481 4.02492 14.3973 3.33325 13.4106 3.33325H6.67728C5.70978 3.33325 4.86895 3.99992 4.64895 4.94242L3.85645 8.33325"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.70866 16.6666H4.58366C5.27366 16.6666 5.83366 16.1066 5.83366 15.4166V14.5833H14.167V15.4166C14.167 16.1066 14.727 16.6666 15.417 16.6666H17.292C17.867 16.6666 18.3337 16.1999 18.3337 15.6249V10.8333C18.3337 9.45242 17.2145 8.33325 15.8337 8.33325H4.16699C2.78616 8.33325 1.66699 9.45242 1.66699 10.8333V15.6249C1.66699 16.1999 2.13366 16.6666 2.70866 16.6666Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
