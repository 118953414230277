import { FilterTabType, ParksDetailType, ParksShort } from "@common/Types";

export const buildAutoCompletesOptions = (parks: ParksDetailType[] = []) => {
  return parks.map((driver) => ({
    label: driver.phone,
    value: String(driver.id),
    description: driver?.name,
  }));
};

export const buildParksShortFilterOptions = (
  data: ParksShort[],
): FilterTabType[] => {
  return data.map((park) => ({
    label: park.name,
    value: String(park.id),
  }));
};
