import React from "react";

import { ROLES_NAVI } from "@common/Constants";
import {
  AvailableFilterKeys,
  FilterTabType,
  PartialFiltersType,
} from "@common/Types";
import { AuthRender, CitiesFilter, Search } from "@components/Shared";
import { InlineSelectWrapper } from "@components/Shared/inline-select/inline-select-wrapper";

type FilterComponentPropsType = {
  filters: PartialFiltersType;
  setFilters: (filters: PartialFiltersType) => void;
  field: AvailableFilterKeys;
  tabs?: FilterTabType[];
};

type FilterObjectType = {
  [key in AvailableFilterKeys]: {
    component: (props: FilterComponentPropsType) => React.JSX.Element;
  };
};

export const filtersSchema: Partial<FilterObjectType> = {
  city_id: {
    component: (props: FilterComponentPropsType) => (
      <AuthRender roles={[ROLES_NAVI.SUPERADMIN]}>
        <CitiesFilter {...props} />
      </AuthRender>
    ),
  },
  application_status: {
    component: (props: FilterComponentPropsType) => (
      <InlineSelectWrapper<PartialFiltersType>
        data={props.tabs}
        filters={props.filters}
        field={props.field}
        setFilters={props.setFilters}
      />
    ),
  },
  search: {
    component: (props: FilterComponentPropsType) => (
      <Search<PartialFiltersType>
        placeholder="Поиск по ФИО и номеру телефона"
        filters={props.filters}
        setFilters={props.setFilters}
        field={props.field}
      />
    ),
  },
  is_read: {
    component: (props: FilterComponentPropsType) => (
      <InlineSelectWrapper<PartialFiltersType>
        data={props.tabs}
        filters={props.filters}
        field={props.field}
        setFilters={props.setFilters}
      />
    ),
  },
  fraud_status: {
    component: (props: FilterComponentPropsType) => (
      <InlineSelectWrapper<PartialFiltersType>
        data={props.tabs}
        filters={props.filters}
        field={props.field}
        setFilters={props.setFilters}
      />
    ),
  },
  audience: {
    component: (props: FilterComponentPropsType) => (
      <InlineSelectWrapper<PartialFiltersType>
        data={props.tabs}
        filters={props.filters}
        field={props.field}
        setFilters={props.setFilters}
      />
    ),
  },
};
