/**
 * Сервисы для promotions
 */

import { appConfig } from "@api/config";

const src = appConfig.api.promotions;

export const promotionsPath = {
  promotions: `${src}/`,
  promotion_details: (promotionId: string) => `${src}/${promotionId}/`,
  archive_promotion: (promotionId: string) => `${src}/${promotionId}/archive/`,
  promotion_report: (promotionId: string) => `${src}/${promotionId}/report/`,
  promotion_participants: (promotionId: string) =>
    `${src}/${promotionId}/participants/`,
  referral_promotion_reports: (promotionId: string) =>
    `${src}/${promotionId}/drivers/bonuses/report/`,
  drivers_referral_promotion_participants: (promotionId: string) =>
    `${src}/${promotionId}/drivers/referral/activations/`,
  riders_referral_promotion_participants: (promotionId: string) =>
    `${src}/${promotionId}/riders/referral/activations/`,
  ride_promotion_reports: (promotionId: string) =>
    `${src}/${promotionId}/drivers/ride/report`,
  ride_individual_promotion_participants: (promotionId: string) =>
    `${src}/${promotionId}/drivers/ride/personal/drivers/`,
  ride_promotion_participants: (promotionId: string) =>
    `${src}/${promotionId}/drivers/ride/completed/`,
  promotion_parks_participants: (promotionId: string) =>
    `${src}/${promotionId}/drivers/ride/parks/`,
  delete_promotion: (promotionId: string) => `${src}/${promotionId}`,
};
