import { ActionIcon, Popover } from "@mantine/core";
import EmojiPicker from "emoji-picker-react";
import { Field } from "formik";
import React, { useState } from "react";

type Props = {
  name: string;
  placeholder: string;
  error?: string | undefined;
  touched?: boolean | undefined;
  label: JSX.Element;
  setFieldValue: (name: string, value: string) => void;
  emoji?: boolean;
  values?: string;
};

export const FormRowEmoji: React.FC<Props> = ({
  name,
  placeholder,
  error,
  touched,
  label,
  setFieldValue,
  emoji,
  values,
}) => {
  const [opened, setOpened] = useState(false);

  const PopUpHandler = () => setOpened((prev) => !prev);
  return (
    <div className="flex flex-col items-start justify-start space-y-1 w-full relative">
      {label}
      <Field
        name={name}
        id={name}
        placeholder={placeholder}
        className="h-[40px] placeholder-gray-400 w-full pl-3 pr-12 rounded-[4px] border-neutral-border-300  transition-colors relative"
      />
      {error && touched ? (
        <p className="text-red-600 text-sm -mt-1">{error}</p>
      ) : null}
      {emoji && (
        <Popover
          className="absolute right-2 top-[22px] z-50"
          opened={opened}
          onClose={PopUpHandler}
          target={
            <ActionIcon variant="transparent" onClick={PopUpHandler}>
              <svg
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 8.5C5 7.67 5.67 7 6.5 7C7.33 7 8 7.67 8 8.5C8 9.33 7.33 10 6.5 10C5.67 10 5 9.33 5 8.5ZM10 16.5C12.33 16.5 14.31 15.04 15.11 13H4.89C5.69 15.04 7.67 16.5 10 16.5ZM13.5 10C14.33 10 15 9.33 15 8.5C15 7.67 14.33 7 13.5 7C12.67 7 12 7.67 12 8.5C12 9.33 12.67 10 13.5 10ZM20 0H18V2H16V4H18V6H20V4H22V2H20V0ZM18 11C18 15.42 14.42 19 10 19C5.58 19 2 15.42 2 11C2 6.58 5.58 3 10 3C11.46 3 12.82 3.4 14 4.08V1.84C12.77 1.3 11.42 1 9.99 1C4.47 1 0 5.48 0 11C0 16.52 4.47 21 9.99 21C15.52 21 20 16.52 20 11C20 9.95 19.83 8.95 19.53 8H17.4C17.78 8.93 18 9.94 18 11Z"
                  fill="#AEB1BB"
                />
              </svg>
            </ActionIcon>
          }
          position="bottom"
          withArrow
        >
          <EmojiPicker
            onEmojiClick={(e, emoji) => {
              setFieldValue(name, values + emoji.emoji);
            }}
          />
        </Popover>
      )}
    </div>
  );
};
