import { Dialog, Transition } from "@headlessui/react";
import { Spinner } from "phosphor-react";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

import { CloseIcon } from "@assets/Icons";
import { Button } from "@components/Elements";

type Props = {
  title: string;
  isShow: boolean;
  onCancel: () => void;
  onConfirm: (promotionId: string) => void;
  loading: boolean;
};

export const ConfirmModal = ({
  title,
  isShow,
  onConfirm,
  onCancel,
  loading,
}: Props) => {
  const { promotionId } = useParams() as { promotionId: string };

  return (
    <Transition appear show={isShow} as={Fragment}>
      <Dialog
        as="div"
        className="fixed ml-[150px] inset-0 z-10 overflow-y-auto"
        onClose={onCancel}
      >
        <div className="min-h-screen text-center p-6">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block  p-6 w-96 max-w-xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-[8px]">
              <div>
                <Dialog.Title
                  as="h3"
                  className="flex justify-between items-center text-heading-2-a font-semibold text-black pb-[24px]"
                >
                  {title}
                  <CloseIcon className="cursor-pointer" onClick={onCancel} />
                </Dialog.Title>
              </div>
              <div className="px-6 pt-6 border-t border-gray-200 flex items-center justify-center space-x-4">
                <Button
                  view={"brand"}
                  size="md"
                  className={
                    "text-brand-bg-1000 text-body-1-a py-[12px] px-[46px]"
                  }
                  onClick={() => onConfirm(promotionId)}
                >
                  {loading && (
                    <Spinner
                      className="animate-spin"
                      color={"#fff"}
                      size={18}
                      weight={"bold"}
                    />
                  )}
                  Удалить
                </Button>
                <Button
                  view={"transparent"}
                  size="md"
                  className={
                    "hover:border hover:border-brand-bg-1000 text-brand-bg-1000 text-body-1-a py-[12px] px-[46px]"
                  }
                  onClick={onCancel}
                >
                  Отменить
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
