import { httpClient } from "@common/Libs/axios";
import { AccessPages } from "@common/Types";

import { permissionsPath } from "./paths";

/**
 * Описание методов для permissions.
 */

export const permissionsService = {
  getPermissions: () =>
    httpClient.get<AccessPages[]>(permissionsPath.permission),

  postAccess: (accessList: AccessPages) =>
    httpClient.post(permissionsPath.permission, accessList),
};
