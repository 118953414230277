import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { reportsService } from "@api/services";
import { QueryParams } from "@common/Types";

export const useGetRefundReports = (params: QueryParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.REFUND_HISTORY, params],
    queryFn: async () => await reportsService.getRefundReports(params),
    refetchInterval: 15000,
  });
};
