import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { reportsService } from "@api/services";

export const useGetPaymentProviders = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.PAYMENTS],
    queryFn: async () => await reportsService.getPaymentProviders(),
  });
};
