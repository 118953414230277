import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { Typography } from "@components/Elements";

import { TrashIcon } from "./icons";

type Props = {
  image?: string | File | null;
  error?: string;
  touched?: boolean;
  required?: boolean;
  label?: string;
  contextText: string;
  onUploaded: (file: File | null) => void;
};

export const ImageUploadSm = ({
  image = "",
  error,
  touched,
  label,
  onUploaded,
  contextText,
  required,
}: Props) => {
  const [preview, setPreview] = useState<string | null>("");

  useEffect(() => {
    if (!image) return;

    if (typeof image === "string") {
      setPreview(image);
    } else if (image instanceof File) {
      setImagePreview(image);
    }
  }, [image]);

  const setImagePreview = (file: File) => {
    const reader = new FileReader();
    reader.onload = function () {
      const dataURL = reader.result;
      setPreview(dataURL as string);
    };
    reader.readAsDataURL(file);
  };

  const upload = (files: File[]) => {
    const file = files[0];
    setImagePreview(file);
    onUploaded(file);
  };

  const clearImage = () => {
    setPreview(null);
    onUploaded(null);
  };

  const widthClasses =
    "w-[110px] h-[110px] max-w-[110px] min-w-[110px] max-h-[110px] min-h-[110px]";

  return (
    <div>
      {label && (
        <Typography className="text-neutral-text-800 text-caption-b font-medium pb-[4px] flex flex-row">
          {label}
          {required && <span className="text-error ml-1"> *</span>}
        </Typography>
      )}

      {preview ? (
        <div
          className={clsx(
            widthClasses,
            "relative p-0 border rounded-[4px] overflow-hidden",
          )}
        >
          <img
            src={preview}
            alt="preview_photo"
            className="w-full h-full object-cover"
          />
          <TrashIcon
            className="absolute top-1 right-1 cursor-pointer"
            onClick={clearImage}
          />
        </div>
      ) : (
        <Dropzone
          onDrop={upload}
          // maxSize={0.5 * 1024 ** 2}// 500kb
          accept={IMAGE_MIME_TYPE}
          className={clsx(
            widthClasses,
            "flex justify-center items-center border-[1px] rounded-1 overflow-hidden",
          )}
        >
          {() => (
            <Typography
              variant="body-2-b"
              color="neutral-800"
              className="font-medium h-full w-full"
            >
              {contextText}
            </Typography>
          )}
        </Dropzone>
      )}
      <p className="text-red-600 text-sm mt-1 max-w-[110px]">
        {error && touched && "Загрузите фотографию"}
      </p>
    </div>
  );
};
