import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { applicationService } from "@api/services";

type Params = {
  id: string;
};

export const usePatchLandingApproveDriverApplication = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: Params) =>
      await applicationService.approveLandingDriverApplications(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.LANDING_DRIVER_APPLICATIONS],
      });
    },
  });
};
