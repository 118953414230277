import { CaretDown, CreditCard } from "phosphor-react";
import React from "react";

import { useGetPaymentProviders } from "@api/queries";
import { PartialFiltersType, ProviderType } from "@common/Types";
import { parseProviders } from "@common/Utils";
import { Loader } from "@components/Elements";

import { CheckboxList } from "../checkbox-list";

type Props = {
  filters: PartialFiltersType;
  setFilters: (value: PartialFiltersType) => void;
};

export const TerminalFilter = ({ filters, setFilters }: Props) => {
  const { data: providers, isPending: providersLoading } =
    useGetPaymentProviders();

  const options =
    providers?.map((provider: ProviderType) => ({
      label: provider.name,
      value: String(provider.id),
    })) || [];

  return (
    <Loader loading={providersLoading} height={250}>
      <CheckboxList
        icon={CaretDown}
        MainIcon={<CreditCard />}
        title={"Терминалы"}
        filters={filters}
        setFilters={setFilters}
        field={"provider_id"}
        options={parseProviders(options)}
        singleChoice={true}
      />
    </Loader>
  );
};
