import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { parksService } from "@api/services";
import { ParksDetailType } from "@common/Types";

export const useGetParkDetail = (parkId: string) => {
  return useQuery<ParksDetailType>({
    queryKey: [QUERY_KEYS.PARKS_DETAIL, parkId],
    queryFn: async () => await parksService.getParkDetail(parkId),
  });
};
