import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { supportChatService } from "@api/services";
import { ChatListTypes, ChatMessagesFilterTypes } from "@common/Types";

type Params = ChatMessagesFilterTypes & {
  enabled?: boolean;
};

export const useGetDriversChatListDetail = (filters?: Params) => {
  const { enabled = true, search = "", ...otherFilter } = filters ?? {};

  return useQuery<ChatListTypes>({
    queryKey: [QUERY_KEYS.SUPPORT_DRIVERS_CHAT_MESSAGE_LIST_EXACT, filters],
    queryFn: async () =>
      await supportChatService.getDriversMessages<ChatListTypes>({
        search,
        ...otherFilter,
      }),
    enabled: enabled,
    placeholderData: keepPreviousData,
  });
};
