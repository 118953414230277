import { useId } from "@mantine/hooks";
import clsx from "clsx";
import { Field } from "formik";
import { FieldProps } from "formik/dist/Field";
import React from "react";

import { isTargetNavi } from "@assets/Flags";

import { InputPhoneMask } from "../../input-phone-mask";

type Props = {
  name: string;
  label: string;
  placeholder: string;
  error?: string;
  touched?: boolean;
};

const format = isTargetNavi ? "+996 (###) ##-##-##" : "+7 (###) ### ####";
const defaultPlaceholder = isTargetNavi
  ? "+996 (___) ___ ____"
  : "+7 (___) ___ ____";

export const ContactFormPhoneField = ({
  name,
  label,
  error,
  touched,
  placeholder = defaultPlaceholder,
}: Props) => {
  const id = useId();
  const htmlFor = `${name}-${id}`;

  return (
    <div className={"w-full flex flex-col"}>
      <div className="flex flex-col items-start">
        <label
          htmlFor={htmlFor}
          className={
            "text-gray-200 text-xs font-medium tracking-wide uppercase mb-1"
          }
        >
          {label}
        </label>
        <Field
          id={htmlFor}
          autoComplete="off"
          name={name}
          render={({ field }: FieldProps) => {
            return (
              <InputPhoneMask
                {...field}
                className={clsx({
                  "placeholder-gray-400 w-full p-4 rounded-xl focus:outline-none  text-gray-200 bg-dark-400 border-none":
                    isTargetNavi,
                  "placeholder-[#969DA1] text-white bg-[#2F3437] text-[16px] w-full py-[10px] px-[16px] rounded-[8px] focus:outline-none":
                    !isTargetNavi,
                })}
                format={format}
                placeholder={placeholder}
                onBlur={(e) => (e.target.placeholder = placeholder)}
                onFocus={(e) => (e.target.placeholder = defaultPlaceholder)}
              />
            );
          }}
        />
      </div>
      {error && touched ? (
        <div className="flex justify-start text-sm text-red-600 mt-1">
          {error}
        </div>
      ) : null}
    </div>
  );
};
