import { httpClient } from "@common/Libs/axios";
import { CitiesType } from "@common/Types";

import { citiesPath } from "./paths";

export const citiesService = {
  getCities: (skip: number, limit: number) =>
    httpClient.get<CitiesType>(citiesPath.cities, {
      params: { skip, limit },
    }),
};
