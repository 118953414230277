import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { useEffect } from "react";

const AMPLITUDE_API_KEY = "a27693c64f3039c336544fce7cd5b1a9";

export const AmplitudeSetup = () => {
  useEffect(() => {
    amplitude.init(AMPLITUDE_API_KEY, {
      fetchRemoteConfig: true,
      autocapture: {
        elementInteractions: true,
      },
    });

    const sessionReplay = sessionReplayPlugin({
      sampleRate: 1,
    });

    amplitude.add(sessionReplay);
  }, []);

  return null;
};
