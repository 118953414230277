/**
 * Сервисы для riders
 */

import { appConfig } from "@api/config";

const src = appConfig.api.dashboard;

export const ridersPath = {
  riders: `${src}/riders/`,
  clean_riders_debt: (rider_id: string) =>
    `${src}/riders/${rider_id}/clear_debt/`,
};
