import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { usersService } from "@api/services";
import { UsersFilterType, UsersResponseType } from "@common/Types";

type Params = {
  filters: UsersFilterType;
  skip: number;
  limit: number;
};

export const useGetUsers = ({ filters, skip, limit }: Params) => {
  return useQuery<UsersResponseType>({
    queryKey: [QUERY_KEYS.DASHBOARD_USERS, filters, skip, limit],
    queryFn: async () => await usersService.getUsers(filters, skip, limit),
  });
};
