import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";
import { PromotionFiltersType, PromotionType } from "@common/Types";

type Params = {
  limit: number;
  skip: number;
  filters: PromotionFiltersType;
};

export const useGetPromotions = ({ limit, skip, filters }: Params) => {
  const { isError, error, data, refetch, ...query } = useQuery<PromotionType>({
    queryKey: [QUERY_KEYS.PROMOTIONS, limit, skip, filters],
    queryFn: async () =>
      await promotionsService.getPromotions(limit, skip, filters),
  });

  return { isError, error, response: data, refetch, ...query };
};
