import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { DriverParams, DriverType } from "@common/Types";

export const useGetDrivers = ({ limit, skip, filters }: DriverParams) => {
  const { data, ...query } = useQuery<DriverType>({
    queryKey: [QUERY_KEYS.DRIVERS, limit, skip, filters],
    queryFn: async () =>
      await driversService.getDrivers({ limit, skip, filters }),
  });

  return { response: data, ...query };
};
