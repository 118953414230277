import { Field, useField } from "formik";
import { isArray } from "radash";
import React from "react";

import { Params } from "@common/Types";
import { cn } from "@common/Utils";
import { Flexbox, Grid, TextInput, Typography } from "@components/Elements";

type Props<T> = {
  title?: string;
  params: Params<T>[] | Params<T>;
  isEdit: boolean;
  isExtended?: boolean;
};

export const LimitField = <T,>({
  title,
  params,
  isEdit,
  isExtended,
}: Props<T>) => {
  const transformedParams = isArray(params) ? params : [params];

  return (
    <Flexbox flexDirection="flex-col" className="gap-2">
      {title && (
        <Typography
          color="neutral-800"
          variant="body-2-b"
          className="font-medium"
        >
          {title}
        </Typography>
      )}

      <Grid
        columns="auto-fill"
        minColumnWidth={238}
        bgColor="neutral-50"
        className={cn("w-full py-2 px-4 rounded-lg", {
          "bg-transparent": isExtended,
        })}
      >
        {transformedParams.map((param: Params<T>, index: number) => {
          const { name, field, disabled = false } = param;
          const [fieldProps, meta] = useField(String(field));

          const currentValue = fieldProps.value;

          return (
            <div
              className="flex flex-col gap-1"
              key={`${String(field)}-${index}`}
            >
              {!isEdit ? (
                <>
                  <Typography
                    color="neutral-500"
                    variant="body-2-b"
                    as="dt"
                    className="font-medium"
                  >
                    {name}
                  </Typography>

                  <Typography color="neutral-800" as="dd">
                    {currentValue ?? "-"}
                  </Typography>
                </>
              ) : (
                <Field
                  name={field}
                  label={name}
                  placeholder="Введите значение"
                  disabled={disabled}
                  error={(meta.touched && meta.error) || null}
                  as={TextInput}
                />
              )}
            </div>
          );
        })}
      </Grid>
    </Flexbox>
  );
};
