import { Tabs as MantineTabs } from "@mantine/core";
import React from "react";

import { useTabControl } from "@common/Hooks";

import { TABS_VIEW_CLASSES } from "./variants";

type TabsType = {
  label: string;
  value: string | number;
};

type Props = {
  tabs: TabsType[];
  children: (tab: TabsType) => React.ReactNode;
  onChange?: () => void;
  activeIndex?: number;
  view?: string;
};

export const Tabs = ({
  tabs,
  children,
  onChange,
  activeIndex = 0,
  view = "default",
}: Props) => {
  const { activeTab, setActiveTab } = useTabControl(activeIndex);

  const onTabChange = (tabIndex: number) => {
    setActiveTab(tabIndex);
    onChange?.();
  };

  return (
    <MantineTabs
      active={activeTab}
      onTabChange={onTabChange}
      classNames={TABS_VIEW_CLASSES[view]}
    >
      {tabs.map((tab, index) => (
        <MantineTabs.Tab key={index} label={tab.label}>
          {children(tab)}
        </MantineTabs.Tab>
      ))}
    </MantineTabs>
  );
};
