import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { parksService } from "@api/services";
import { ParksShort } from "@common/Types";

export const useGetParksShort = (cityId?: string | number) => {
  return useQuery<ParksShort[]>({
    queryKey: [QUERY_KEYS.PARKS_SHORT, cityId],
    queryFn: async () => await parksService.getParksShort(cityId),
  });
};
