import React from "react";

export const TariffIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8474 6C14.4664 5.408 13.6734 5 12.7504 5C11.8274 5 11.0344 5.408 10.6484 6"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3997 3.972C16.3757 2.781 14.6767 2 12.7487 2C10.8187 2 9.11873 2.783 8.09473 3.975"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 19.5621V16.0781C4.75 14.9731 5.645 14.0781 6.75 14.0781H18.75C19.855 14.0781 20.75 14.9731 20.75 16.0781V19.5621V21.0001C20.75 21.5521 20.302 22.0001 19.75 22.0001H17.75C17.198 22.0001 16.75 21.5521 16.75 21.0001V20.0001H8.75V21.0001C8.75 21.5521 8.302 22.0001 7.75 22.0001H5.75C5.198 22.0001 4.75 21.5521 4.75 21.0001V19.5621V19.5621Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 14.078L7.576 10.544C7.788 9.64 8.595 9 9.524 9H16.063C17.01 9 17.827 9.664 18.021 10.59L18.75 14.078"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6904 17H15.6904"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 17H7.75"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 14L20.75 13"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 14L4.75 13"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
