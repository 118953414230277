import { SegmentedControl, SegmentedControlItem } from "@mantine/core";
import React, { useState } from "react";

import { MOBILE_WIDTH } from "@common/Constants";
import { useDimension } from "@common/Hooks";

import { Col } from "../flexbox";

import { SEGMENT_TAB_VIEW_CLASSES } from "./variants";

type Props<T> = {
  tabs: T[];
  callback?: () => void;
  view?: "default";
  activeIndex?: number;
  children: (tab: T) => React.ReactNode;
  className?: string;
};

export const SegmentTabs = <T extends SegmentedControlItem>({
  tabs,
  callback,
  activeIndex = 0,
  view = "default",
  className,
  children,
}: Props<T>) => {
  const { widthD } = useDimension();
  const [activeTab, setActiveTab] = useState(tabs[activeIndex]);

  const onChange = (value: string) => {
    const tabValue = tabs.find((tab) => tab.value === value);

    if (tabValue) {
      setActiveTab(tabValue);
      callback?.();
    }
  };

  const segmentValue = activeTab.value;
  const fullWidth = widthD < MOBILE_WIDTH;
  const orientation = widthD > MOBILE_WIDTH ? "horizontal" : "vertical";

  return (
    <Col className={className}>
      <SegmentedControl
        data={tabs}
        onChange={onChange}
        value={segmentValue}
        orientation={orientation}
        fullWidth={fullWidth}
        className={orientation === "vertical" ? "w-[100%]" : ""}
        classNames={SEGMENT_TAB_VIEW_CLASSES[view]}
      />
      {children(activeTab)}
    </Col>
  );
};
