import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { parksService } from "@api/services";
import { ParkParams, ParksType } from "@common/Types";

export const useGetParks = ({ limit, skip, search }: ParkParams) => {
  return useQuery<ParksType>({
    queryKey: [QUERY_KEYS.PARKS, limit, skip, search],
    queryFn: async () => await parksService.getParks({ limit, skip, search }),
  });
};
