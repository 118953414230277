import { TableType } from "@common/Types";

export type TableSchemeType<T, D> = (handlers: D) => TableType<T>[];

export const renderTableSchema = <T>(scheme: TableType<T>[]) => {
  return scheme;
};

export const renderTableSchemaCallBack = <T, D>(
  scheme: TableSchemeType<T, D>,
) => {
  return (values: D) => scheme(values);
};
