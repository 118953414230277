import { httpClient } from "@common/Libs/axios";
import { ImageType } from "@common/Types";

import { imagesPath } from "./paths";

/**
 * Описание методов images.
 */

export const imagesService = {
  uploadImage: (file: any) => {
    const formats: FormData = new FormData();
    formats.append("file", file, file.name);
    return httpClient.post<ImageType, FormData>(imagesPath.images, formats);
  },
};
