import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { reportsService } from "@api/services";
import { useDelay } from "@common/Hooks";
import { queryClient } from "@common/Libs/react_query";
import { AccountReportFormValues } from "@common/Types";

export const useCreateAccountReport = () => {
  const updateReportsList = useDelay(() => {
    return queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.ACCOUNT_REPORTS],
    });
  }, 1000);

  return useMutation({
    mutationFn: (report: AccountReportFormValues) =>
      reportsService.createAccountReport(report),

    onSuccess: () => {
      updateReportsList();
      toast.success(`Создание отчёта успешно принято в обработку`);
    },
  });
};
