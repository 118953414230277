import React from "react";

import { useTabAccess } from "@common/Hooks";
import { FilterTabType } from "@common/Types";
import { InlineSelect } from "@components/Shared";

type Props<T> = {
  filters: T;
  setFilters: (filters: T) => void;
  field: keyof T;
  data?: FilterTabType[];
  size?: "xs" | "sm" | "md" | "lg" | "xl";
};

export const InlineSelectWrapper = <T,>({
  filters,
  setFilters,
  field,
  data,
  size,
}: Props<T>) => {
  const tabs = useTabAccess({
    field: "accessKey",
    tabs: data as FilterTabType[],
  });

  return (
    <InlineSelect
      filters={filters}
      setFilters={setFilters}
      field={field}
      data={tabs}
      size={size}
    />
  );
};
