/**
 * Сервисы для images
 */

import { appConfig } from "@api/config";

const src = appConfig.api.images;

export const imagesPath = {
  images: `${src}/upload/`,
};
