import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { CreateDriverType } from "@common/Types";

export const useCreateDriver = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateDriverType) => driversService.createDriver(data),
    onSuccess: () => {
      toast.success("Водитель успешно зарегистрирован");
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DRIVERS] });
    },
  });
};
