import { FilterTabType } from "@common/Types";

export const parseProviders = (options: FilterTabType[]): FilterTabType[] => {
  const newOptions = options.map((obj) => {
    switch (obj.label) {
      case "Nurtelecom":
        return { ...obj, label: "О! Деньги" };
      default:
        return obj;
    }
  });

  return newOptions;
};
