import { SegmentedControl } from "@mantine/core";
import React from "react";

import { FilterTabType } from "@common/Types";

type Props<T> = {
  filters: T;
  setFilters: (filters: T) => void;
  field: keyof T;
  data: FilterTabType[];
  size?: "xs" | "sm" | "md" | "lg" | "xl";
};

export const InlineSelect = <T,>({
  filters,
  field,
  setFilters,
  data,
  size = "sm",
}: Props<T>) => {
  const onChange = (value: string) => {
    const obj = {
      ...filters,
      [field]: value,
    };
    setFilters(obj);
  };

  return (
    <div className="inline-flex items-center">
      <SegmentedControl
        value={filters[field] as unknown as string}
        size={size}
        className={"z-0"}
        classNames={{
          labelActive: "h-[32px]",
          root: "h-[40px] bg-[#F2F2F2]",
        }}
        onChange={onChange}
        data={data}
      />
    </div>
  );
};
