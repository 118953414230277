import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { applicationService } from "@api/services";
import { DriverApplicationDeclineType } from "@common/Types";

type Params = {
  id: string;
  data: DriverApplicationDeclineType;
};

export const usePutDeclineDriverApplication = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: Params) =>
      await applicationService.declineDriverApplication(id, data),
    onSuccess: () => {
      toast.success("Заявка успешно отклонена");
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DRIVER_APPLICATIONS_DETAIL],
      });
    },
  });
};
