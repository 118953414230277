import * as Yup from "yup";

import { ConfigsType } from "@common/Types";

export const booleanNumberValidation = Yup.number()
  .required("Обязательное поле")
  .min(0, "Значение не может быть меньше 0")
  .max(1, "Значение не может быть больше 1");

export const numberValidation = Yup.number()
  .required("Обязательное поле")
  .transform((value) => (Number.isNaN(value) ? null : value))
  .min(0, "Число не может быть меньше 0")
  .max(9999999999, "Число не может быть больше 9999999999");

export const offerExpirationValidation = Yup.number()
  .required("Обязательное поле")
  .transform((value) => (Number.isNaN(value) ? null : value))
  .min(10, "Время не может быть меньше 10 сек")
  .max(30, "Время не может быть больше 30 сек");

export const intervalValidation = Yup.number()
  .required("Обязательное поле")
  .transform((value) => (Number.isNaN(value) ? null : value))
  .min(30, "Интервал между предложениями не должен быть меньше 30 сек")
  .max(300, "Интервал между предложениями не должен быть больше 300 сек");

export const quantityLimitValidation = Yup.number()
  .required("Обязательное поле")
  .transform((value) => (Number.isNaN(value) ? null : value))
  .min(1, "Лимит по количеству предложении не должен быть меньше 1")
  .max(20, "Лимит по количеству предложении не должен быть больше 20");

export const textValidation = Yup.string().required("Обязательное поле");

export const getDynamicPercentValidation = (min = 0, max = 100) =>
  Yup.number()
    .required("Обязательное поле")
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(min, `Число не должно быть меньше ${min}`)
    .max(max, `Число не должно быть больше ${max}`);

export const getDynamicValidationSchema = (name: keyof ConfigsType) => {
  return Yup.object().shape({
    [name]: Yup.number().required("Обязательное поле"),
  });
};

export const getDynamicValidationArraySchema = (
  names: Array<keyof ConfigsType>,
) => {
  return Yup.object().shape(
    names.reduce((acc: any, name) => {
      acc[name] = Yup.number().required("Обязательное поле");
      return acc;
    }, {}),
  );
};
