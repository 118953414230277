import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { settingsService } from "@api/services/settings";
import { FeesType } from "@common/Types";

export const useGetParkFees = (parkId: string) => {
  return useQuery<FeesType>({
    queryKey: [QUERY_KEYS.PARK_FEES, parkId],
    queryFn: async () => await settingsService.getParkFees(parkId),
  });
};
