import { useMemo } from "react";

import { useGetPermissions } from "@api/queries";
import { ROLES } from "@common/Constants";
import { RoleType } from "@common/Types";
import { getUserRole } from "@common/Utils";

type Tab = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type Props<T extends Tab> = {
  field?: keyof T;
  tabs: T[];
};

export const useTabAccess = <T extends Tab>({ field, tabs }: Props<T>): T[] => {
  const userRole = getUserRole() as RoleType;
  const isDispatcher = [ROLES.MOTORADMIN, ROLES.NAVIADMIN].includes(userRole);
  const { response } = useGetPermissions(isDispatcher);

  return useMemo(() => {
    if (isDispatcher) {
      const activeTabValues = response?.reduce<Set<string>>(
        (acc, { name, is_active }) => {
          if (is_active) {
            acc.add(name);
          }
          return acc;
        },
        new Set(),
      );

      return tabs.filter((item) => {
        if (field && item[field]) {
          return activeTabValues?.has(item[field]);
        }
        return item;
      });
    }

    return tabs;
  }, [isDispatcher, response, tabs, field]);
};
