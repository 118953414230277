export const parseName = (
  first: string | undefined | null,
  last: string | undefined | null,
  second?: string | undefined | null,
) => {
  if (!first && !second && !last) {
    return "Не указано";
  }
  return `${first || ""} ${last || ""} ${second || ""}`;
};
