import React from "react";

import {
  AppProvidersLayout,
  DashboardProvidersLayout,
} from "@components/Layouts";
import { AppRouter } from "@features/index";

export const AppRoot = () => {
  return (
    <AppProvidersLayout>
      {/* TODO: on finished migrate to nav and motor  move dashboard providersLayout to DashboardLayout  */}
      <DashboardProvidersLayout>
        <AppRouter />
      </DashboardProvidersLayout>
    </AppProvidersLayout>
  );
};
