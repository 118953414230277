import { Group, Select, Title } from "@mantine/core";
import { Buildings } from "phosphor-react";
import React from "react";

import { AVAILABLE_LANGUAGE_FILTER } from "@common/Libs/i18next";

type Props = {
  filterValue?: string;
  setFilterValue: (value: string) => void;
};

export const LanguageFilterMobile = ({
  filterValue,
  setFilterValue,
}: Props) => {
  return (
    <Group spacing={"xs"} direction="column">
      <Title order={5}>Язык</Title>
      <Select
        icon={<Buildings size={15} />}
        placeholder="Язык"
        data={AVAILABLE_LANGUAGE_FILTER}
        value={filterValue}
        onChange={setFilterValue}
        clearable
      />
    </Group>
  );
};
