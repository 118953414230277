import clsx from "clsx";
import React from "react";

interface LazyImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  classNames?: {
    img?: string;
    loader?: string;
  };
}

/**
 * A component for lazy loading images using the Intersection Observer API.
 *
 * @param {LazyImageProps} props - The properties for the LazyImage component.
 * @returns {JSX.Element} The rendered image element.
 */
export const LazyImage: React.FC<LazyImageProps> = ({
  src,
  alt,
  className,
  classNames = {},
  ...props
}) => {
  const { img: imageClasses = "", loader: loaderClasses } = classNames;

  return src ? (
    <img
      src={src}
      alt={alt}
      className={clsx(className, imageClasses)}
      loading="lazy"
      {...props}
    />
  ) : (
    <div
      className={clsx(
        "w-[380px] h-[180px] bg-neutral-300 rounded-20",
        loaderClasses,
      )}
    />
  );
};
