import React from "react";

import { ActiveFilterType, PartialFiltersType } from "@common/Types";

import { filtersSchema } from "./filters-schema";

type Props = {
  filters: PartialFiltersType;
  setFilters: (values: PartialFiltersType) => void;
  activeFilters: ActiveFilterType[];
};

export const DesktopFilters = ({
  filters,
  setFilters,
  activeFilters,
}: Props) => {
  return (
    <div className="w-full flex items-center justify-between flex-wrap">
      <div className="flex items-center flex-wrap gap-2">
        {activeFilters.map((item) => {
          const currentFilter = filtersSchema[item.key];
          if (!currentFilter) {
            return null;
          }

          const { component: FilterComponent } = currentFilter;

          return (
            <FilterComponent
              key={item.key}
              filters={filters}
              setFilters={setFilters}
              field={item.key}
              tabs={item.tabs}
            />
          );
        })}
      </div>
    </div>
  );
};
