import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { supportChatService } from "@api/services";
import { ChatListTypes, ChatMessagesFilterTypes } from "@common/Types";

type Params = ChatMessagesFilterTypes & {
  enabled?: boolean;
};

export const useGetRidersChatList = (filters?: Params) => {
  const { enabled = true, search = "", ...otherFilter } = filters ?? {};

  return useQuery<ChatListTypes>({
    queryKey: [QUERY_KEYS.SUPPORT_RIDERS_CHAT_MESSAGE_LIST, filters],
    queryFn: async () => {
      return await supportChatService.getRidersMessages<ChatListTypes>({
        search,
        ...otherFilter,
      });
    },
    enabled: enabled,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });
};
