/**
 * Сервисы для users
 */

import { appConfig } from "@api/config";

const src = appConfig.api.users;
const srcDashboard = appConfig.api.dashboard;

export const usersPath = {
  verify_code: `${src}/verify-code/`,
  delete_account: `${src}/delete-account/`,
  admins: `${srcDashboard}/admins/`,
  admin: `${srcDashboard}/admin/`,
  editAdmin: (id: string) => `${srcDashboard}/admin/${id}`,
  editAdminStatus: (id: string) => `${srcDashboard}/admins/${id}/status/`,
  editAdminPassword: (id: string) =>
    `${srcDashboard}/admin/${id}/password/reset/`,
};
