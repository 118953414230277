import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { applicationService } from "@api/services";
import { ApplicationDetailType } from "@common/Types";

export const useGetDriverApplicationDetail = (id: string) => {
  return useQuery<ApplicationDetailType>({
    queryKey: [QUERY_KEYS.DRIVER_APPLICATIONS_DETAIL, id],
    queryFn: async () =>
      await applicationService.getDriverApplicationDetail(id),
  });
};
