import React from "react";

import { TableType } from "@common/Types";
import { Loader } from "@components/Elements";

import { NoResult, Pagination, Table } from "..";

type Props<T> = {
  data?: T[];
  dataIdKey?: string;
  tableSchema: TableType<T>[];
  pagination: {
    skip: number;
    setSkip: (page: number) => void;
    limit: number;
    length: number;
  };
  loading?: boolean;
  onRowClick?: (row: T) => void;
};

export const CustomTable = <T,>({
  data,
  dataIdKey = "id",
  loading,
  tableSchema,
  onRowClick,
  pagination,
}: Props<T>) => {
  return (
    <div className={"w-full flex flex-col"}>
      <Loader loading={loading} bordered height={400} className={"mt-0"}>
        {data && data?.length ? (
          <div className={"flex flex-col"}>
            <Table>
              <thead className="bg-white">
                <tr>
                  {tableSchema.map(
                    (row, index) =>
                      !row.isRemoved && (
                        <th
                          key={index}
                          className={`px-[16px] py-[14px] text-left !text-body-2-a leading-[20px] border-neutral-border-200 font-medium text-neutral-text-500 tracking-wider ${row.thCellClassName}`}
                        >
                          {row.title}
                        </th>
                      ),
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-neutral-200 bg-transparent relative">
                {Array.isArray(data) &&
                  data?.map((item: T) => (
                    <tr
                      key={item[dataIdKey as keyof T] as any}
                      onClick={() => onRowClick?.(item)}
                      className={`transition-colors hover:bg-gray-50 bg-white ${
                        onRowClick ? "cursor-pointer" : ""
                      }`}
                    >
                      {tableSchema.map((row, index) =>
                        !row.isRemoved ? (
                          <td
                            key={index}
                            className={`py-2 px-4 !text-body-2-b text-neutral-text-800 font-medium whitespace-nowrap truncate md:w-full ${row.tdCellClassName}`}
                          >
                            {row.render
                              ? row.render(item)
                              : row.key
                              ? String(item[row.key])
                              : null}
                          </td>
                        ) : null,
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <NoResult className={"my-0"} />
        )}
      </Loader>
      <Pagination {...pagination} />
    </div>
  );
};
