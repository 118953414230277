import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { applicationService } from "@api/services";
import { DriverApplicationCreateUpdateType } from "@common/Types";

type Params = {
  id: string;
  data: DriverApplicationCreateUpdateType;
};

export const usePutApproveDriverApplication = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: Params) =>
      await applicationService.approveDriverApplication(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DRIVER_APPLICATIONS_DETAIL],
      });
    },
  });
};
