import React, { ReactNode } from "react";

import { Flexbox, Typography } from "@components/Elements";

type Props = {
  children: ReactNode;
  title?: string;
  renderRightItem?: JSX.Element;
};

export const Block = ({ children, title, renderRightItem }: Props) => {
  return (
    <div className="shadow-md p-4 bg-neutral-bg-0 space-y-4 rounded-lg divide-y divide-neutral-border-100">
      {title && (
        <Flexbox
          justifyContent="justify-between"
          alignItems="items-center"
          className="gap-2"
        >
          <Typography
            color="neutral-800"
            variant="heading-2-b"
            as="h2"
            className="font-semibold"
          >
            {title}
          </Typography>

          {renderRightItem}
        </Flexbox>
      )}

      {children}
    </div>
  );
};
