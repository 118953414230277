import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { queryClient } from "@common/Libs/react_query";

import "@assets/Styles/app.css";
import "@assets/Styles/index.scss";
import "@common/Libs/i18next";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";

import { AppRoot } from "./navi_and_motor";

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AppRoot />
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>,
  );
}
