import {
  endOfDay,
  endOfMonth,
  endOfToday,
  endOfYesterday,
  format,
  isDate,
  parse,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfYesterday,
  subDays,
} from "date-fns";
import dayjs from "dayjs";
import { chain, isArray, isEmpty, omit } from "radash";
import { AnyObject } from "yup";

import { formatOfDate } from "@common/Constants";
import { DateRageFilterType } from "@common/Types";
import { formatDate, removeEmptyFilterValues } from "@common/Utils";

export const getDateString = (date = "", dateFormat = "yyyy-MM-dd") => {
  return format(new Date(date), dateFormat);
};

export const getYesterday = (dateFormat?: string): DateRageFilterType => {
  return [
    formatDate(startOfYesterday(), dateFormat ? dateFormat : formatOfDate),
    formatDate(endOfYesterday(), dateFormat ? dateFormat : formatOfDate),
  ];
};

export const getToday = (dateFormat?: string): DateRageFilterType => {
  return [
    formatDate(startOfToday(), dateFormat ? dateFormat : formatOfDate),
    formatDate(endOfToday(), dateFormat ? dateFormat : formatOfDate),
  ];
};

export const getWeek = (dateFormat?: string): DateRageFilterType => {
  return [
    formatDate(subDays(new Date(), 7), dateFormat ? dateFormat : formatOfDate),
    formatDate(new Date(), dateFormat ? dateFormat : formatOfDate),
  ];
};

export const getMonth = (dateFormat?: string): DateRageFilterType => {
  return [
    formatDate(
      startOfMonth(new Date()),
      dateFormat ? dateFormat : formatOfDate,
    ),
    formatDate(endOfMonth(new Date()), dateFormat ? dateFormat : formatOfDate),
  ];
};

export const getAll = (): DateRageFilterType => {
  return [null, null];
};

export const formatDateRange = (
  range: string,
  dateFormat?: string,
): DateRageFilterType => {
  switch (range) {
    case "today":
      return getToday(dateFormat);
    case "yesterday":
      return getYesterday(dateFormat);
    case "week":
      return getWeek(dateFormat);
    case "month":
      return getMonth(dateFormat);
    case "all":
      return getAll();
    default:
      return getAll();
  }
};

export function formatTime(seconds: number | null) {
  if (!seconds) return "";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;

  return [
    String(hours).padStart(2, "0"),
    String(minutes).padStart(2, "0"),
    String(secs).padStart(2, "0"),
  ].join(":");
}

export const isDateObjectRange = (
  dates: [Date | null, Date | null],
): dates is [Date, Date] => {
  return isArray(dates) && isDate(dates[0]) && isDate(dates[1]);
};

export const buildDateRangeFromTextRange =
  (key: string) => (filters: AnyObject) => {
    if (isEmpty(filters)) return filters;
    if (!filters[key]) return filters;

    if (filters[key] === "custom" || filters[key] === "all") {
      return {
        ...omit(filters, [key]),
      };
    }

    return {
      ...omit(filters, [key]),
      date_range: formatDateRange(filters[key] as string),
    };
  };

export const buildDateRangeFiltersValues = <T>(filters: T): Partial<T> => {
  const chained = chain(
    removeEmptyFilterValues,
    buildStartEndDateFromDateRange("date_range"),
  );
  return chained(filters);
};

const buildStartEndDateFromDateRange =
  (key: string) => (filters: AnyObject) => {
    if (!filters) return filters;
    if (filters[key] && isArray(filters[key])) {
      const [start, end] = filters[key] as [
        string | undefined | null,
        string | undefined | null,
      ];
      return {
        ...omit(filters, [key]),
        ...(start
          ? {
              start: startOfDay(
                parse(start, "dd-MM-yyyy", new Date()),
              ).toISOString(),
            }
          : {}),
        ...(end
          ? {
              end: endOfDay(parse(end, "dd-MM-yyyy", new Date())).toISOString(),
            }
          : {}),
      };
    }

    return filters;
  };

export const isDayNotMoreOrLessThenSelected =
  (selectedDay: Date, allowedOffset: number) =>
  (dateForCheck: Date): boolean => {
    const selectedDate = dayjs(selectedDay);
    const dateToCheck = dayjs(dateForCheck);
    const differenceInDays = selectedDate.diff(dateToCheck, "day");
    return (
      differenceInDays > allowedOffset || differenceInDays < -allowedOffset
    );
  };
