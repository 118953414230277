import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";
import { PromotionSubmitType } from "@common/Types";
import { showErrorToast } from "@common/Utils";

export const useCreatePromotion = () => {
  const q = useQueryClient();
  const { isError, error, data, ...query } = useMutation({
    mutationFn: (data: PromotionSubmitType) =>
      promotionsService.createPromotion(data),
    onSuccess: () => {
      q.invalidateQueries({ queryKey: [QUERY_KEYS.PROMOTIONS] });
    },
    onError: (error) => {
      showErrorToast(error as AxiosError);
    },
  });

  return { isError, error, response: data, ...query };
};
