import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { tariffsService } from "@api/services";
import { TariffType } from "@common/Types";

type Params = {
  enabled?: boolean;
};

export const useGetTariffs = (params?: Params) => {
  return useQuery<TariffType[]>({
    queryKey: [QUERY_KEYS.TARIFFS, params],
    queryFn: async () => await tariffsService.getTariffs(),
    enabled: params?.enabled || true,
  });
};
