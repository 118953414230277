import { useMutation } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { newsService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { NewsFormType } from "@common/Types";

export const useEditNews = () => {
  return useMutation({
    mutationFn: ({ data, type }: { data: NewsFormType; type: string }) => {
      return newsService.editNews(data, type);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.NEWS],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.NEWS_DETAILS],
      });
    },
  });
};
