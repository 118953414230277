/**
 * Сервисы для reports
 */

import { appConfig } from "@api/config";

const srcReports = appConfig.api.reports;
const srcDashboard = appConfig.api.dashboard;

export const reportsPath = {
  dashboard_reports: `${srcDashboard}/reports/`,
  reports: `${srcReports}/`,
  reports_payment: `${srcDashboard}/payment-providers/report/`,
  reports_payment_providers: `${srcDashboard}/payment-providers/`,
  reports_refund_history: `${srcDashboard}/rides/refunds/`,
  reports_account_reports_download: (reportId: string) =>
    `${srcReports}/${reportId}/download/`,
};
