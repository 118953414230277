import { useMutation } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { usersService } from "@api/services";
import { VerifyCodePrams } from "@common/Types";

export const usePostVerifyCode = () => {
  return useMutation({
    mutationKey: [QUERY_KEYS.VERIFY_CODE],
    mutationFn: async (params: VerifyCodePrams) =>
      await usersService.verifyCode(params),
  });
};
