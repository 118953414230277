import { Form, Formik } from "formik";
import { FormikHelpers } from "formik/dist/types";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { usePostVerifyCode } from "@api/queries";
import { LoadingIcon } from "@assets/Icons";
import { VerifyCodeTypes } from "@common/Types";
import { ContactFormInputField } from "@components/Elements";

import { StateValues } from "../../common/types";

const getValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    code: Yup.string()
      .required(t("validation:required-field"))
      .matches(/^\d{6}$/, {
        message: t("validation:invalid-length"),
      }),
  });
};

export type RequestAuthTokenForm = {
  code: string;
};

const initialValues: RequestAuthTokenForm = {
  code: "",
};

type Props = {
  values: StateValues;
  onChange: (values: StateValues) => void;
};

export const RequestAuthTokenForm = ({
  values: { phone = "" },
  onChange,
}: Props) => {
  const { t, i18n } = useTranslation();

  const {
    mutateAsync: verifyAuthCode,
    data: response,
    isPending: loading,
  } = usePostVerifyCode();

  const validationSchema = React.useMemo(() => {
    return getValidationSchema(t);
  }, [i18n.language]);

  const onSubmit = async (
    values: RequestAuthTokenForm,
    { resetForm }: FormikHelpers<RequestAuthTokenForm>,
  ) => {
    await verifyAuthCode({
      phone: phone as string,
      code: values.code,
    });

    onChange(response as VerifyCodeTypes);
    resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form className={"w-full space-y-6"}>
          <ContactFormInputField
            name={"code"}
            label={t("delete-account.sms-code-field")}
            placeholder={t("delete-account.sms-code-field-placeholder")}
            error={errors.code}
            touched={touched.code}
          />
          <div className={"flex w-full items-center justify-center"}>
            <button
              type={"submit"}
              disabled={loading}
              className="inline-flex items-center justify-center h-10 px-6 space-x-2 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-600 hover:bg-blue-700 focus:shadow-outline focus:outline-none"
            >
              {loading && <LoadingIcon />}
              <span>{t("delete-account.authorization-button")}</span>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
