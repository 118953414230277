import React from "react";

export const LoadingIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={`animate-spin h-5 w-5 ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="text-blue-200"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="text-blue-600"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
};

type Props = {
  loading: boolean | undefined;
  text?: string;
  height?: number | string;
  className?: string;
  bordered?: boolean;
  children?: React.ReactNode;
};

export const Loader = ({
  loading,
  text = "Загрузка...",
  height,
  className,
  bordered,
  children,
}: Props) => {
  return loading ? (
    <div
      className={`w-full flex items-center justify-center bg-white rounded-md mt-[20px] ${
        bordered ? "border border-gray-300" : ""
      } ${className}`}
      style={{ height: height || "100%" }}
    >
      <div className="flex flex-col justify-center items-center space-y-2">
        <LoadingIcon />
        <p className={"text-sm text-gray-600 animate-pulse"}>{text}</p>
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};
