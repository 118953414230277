import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";
import { PromotionParams, PromotionRideParticipantsType } from "@common/Types";

export const useGetRidePromotionParticipants = ({
  promotionId,
  skip,
  limit,
}: PromotionParams) => {
  const { data, ...query } = useQuery<PromotionRideParticipantsType>({
    queryKey: [
      QUERY_KEYS.RIDE_PROMOTION_PARTICIPANTS,
      promotionId,
      skip,
      limit,
    ],
    queryFn: async () =>
      await promotionsService.getRidePromotionParticipants({
        promotionId,
        skip,
        limit,
      }),
  });

  return { response: data, ...query };
};
