import { useMutation } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { usersService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { UserStatusType } from "@common/Types";

type Params = {
  id: string;
  data: UserStatusType;
};

export const useEditUserStatus = () => {
  return useMutation({
    mutationFn: ({ id, data }: Params) => usersService.editUserStatus(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DASHBOARD_USERS] });
    },
  });
};
