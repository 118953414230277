import { Input, InputWrapper } from "@mantine/core";
import React from "react";
import { NumberFormatValues, PatternFormatProps } from "react-number-format";

import { isTargetNavi } from "@assets/Flags";

import { InputPhoneMask } from "../input-phone-mask";

type Props = {
  name: string;
  label: string;
  placeholder: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onValueChange?: (values: NumberFormatValues) => void;
  className?: string;
  description?: string;
  error?: string | undefined | boolean;
  value?: string;
  mask?: string;
  required?: boolean;
};

const format = isTargetNavi ? "+### (###) ##-##-##" : "+# (###) ### ####";

const phoneMask = (props: PatternFormatProps) => {
  return <InputPhoneMask {...props} />;
};

export const PhoneInput: React.FC<Props> = ({
  label,
  description,
  error,
  required,
  ...props
}) => {
  return (
    <InputWrapper
      label={label}
      required={required}
      description={description}
      error={error}
      classNames={{
        root: "w-full flex flex-col",
        label: "text-caption-b",
        description: "text-caption-b mb-[4px]",
        error: "text-caption-a text-negative-text-500 mt-[5px]",
      }}
    >
      <Input
        format={format}
        autoComplete={"off"}
        component={phoneMask}
        classNames={{
          input: "border-neutral-border-300 h-[42px] placeholder:text-body-1-a",
        }}
        {...props}
      />
    </InputWrapper>
  );
};
