import { Button, Drawer, Group, Input } from "@mantine/core";
import { Check, Funnel } from "phosphor-react";
import React, { useState } from "react";

import {
  ActiveFilterType,
  AvailableFilterKeys,
  DateRageFilterType,
  PartialFiltersType,
  PredefinedDateRangeType,
} from "@common/Types";
import { filtersSchema, Search } from "@components/Shared";

interface Props {
  filters: PartialFiltersType;
  setFilters: (values: PartialFiltersType) => void;
  activeFilters: ActiveFilterType[];
}

export const MobileFilters = ({
  filters,
  setFilters,
  activeFilters,
}: Props) => {
  const [localFilters, setLocalFilters] = useState(filters);
  const [showDrawer, setShowDrawer] = useState(false);

  const handleHideDrawer = () => {
    setShowDrawer(false);
  };

  const handleShowDrawer = () => {
    setShowDrawer(true);
  };

  const onClickToApplyFilters = () => {
    setFilters({ ...localFilters });
    handleHideDrawer();
  };

  const onClickToResetFilters = () => {
    setLocalFilters(filters);
    handleHideDrawer();
  };

  const onLocalFilterChange = (filterKey: AvailableFilterKeys) => {
    if (filterKey === "controlled_date_range") {
      return (value: {
        date_range?: DateRageFilterType;
        select_range?: PredefinedDateRangeType;
      }) => {
        setLocalFilters((prevState) => ({
          ...prevState,
          date_range: value?.date_range,
          select_range: value?.select_range,
        }));
      };
    }

    return (value: number | string | string[]) => {
      setLocalFilters((prevState) => ({
        ...prevState,
        [filterKey]: Array.isArray(value) ? [...value] : value,
      }));
    };
  };

  const isSearchFilterExists = activeFilters.find(
    (activeFilters) => activeFilters.key === "search",
  );

  return (
    <div className={"w-full flex flex-col"}>
      <Group spacing={"sm"} position={"apart"}>
        <Input
          component={"button"}
          icon={<Funnel />}
          onClick={handleShowDrawer}
        >
          Фильтры
        </Input>
      </Group>
      <Drawer
        zIndex={10}
        opened={showDrawer}
        onClose={onClickToApplyFilters}
        title="Фильтрация"
        padding="lg"
        size="max-content"
        position={"bottom"}
        classNames={{
          drawer: "overflow-auto",
        }}
      >
        <div className={"w-full flex flex-col mb-[20px] gap-[12px]"}>
          {activeFilters.map((activeFilterItem) => {
            const FilterToRender =
              filtersSchema[activeFilterItem.key]?.component;

            if (!FilterToRender) {
              return null;
            }

            return (
              <FilterToRender
                key={activeFilterItem.key}
                filterValue={localFilters[activeFilterItem.key]}
                setFilterValue={onLocalFilterChange(activeFilterItem.key)}
                tabs={activeFilterItem.tabs}
              />
            );
          })}
        </div>
        <Group spacing={"md"} direction="row" position={"center"}>
          <Button variant="light" color={"red"} onClick={onClickToResetFilters}>
            Сбросить
          </Button>
          <Button
            leftIcon={<Check size={15} color={"#fff"} />}
            onClick={onClickToApplyFilters}
          >
            Применить
          </Button>
        </Group>
      </Drawer>
      {isSearchFilterExists && (
        <div className={"mt-[4px]"}>
          <Search<PartialFiltersType>
            placeholder="Поиск по ФИО и номеру телефона"
            filters={filters}
            setFilters={setFilters}
            field={"search"}
          />
        </div>
      )}
    </div>
  );
};
