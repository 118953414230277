import { httpClient } from "@common/Libs/axios";
import {
  CommonNewsFiltersType,
  NewsDetailResponse,
  NewsDetailType,
  NewsFormType,
} from "@common/Types";
import { buildCommonFiltersValues, commaSerializer } from "@common/Utils";

import { newsPath } from "./paths";

/**
 * Описание методов news.
 */

export const newsService = {
  getNews: (filters?: CommonNewsFiltersType, skip?: number, limit?: number) =>
    httpClient.get<NewsDetailResponse>(newsPath.news, {
      params: { skip, limit, ...buildCommonFiltersValues(filters) },
      paramsSerializer: commaSerializer,
    }),
  createNews: (data: NewsFormType) =>
    httpClient.post<NewsFormType, NewsFormType>(newsPath.news, data),
  getNewsDetail: (id: number | null, type: string) =>
    httpClient.get<NewsDetailType>(newsPath.newsDetail(id), {
      params: { type: type },
    }),
  editNews: (news: NewsFormType, type: string) =>
    httpClient.patch<NewsFormType, NewsFormType>(
      newsPath.newsDetail(news.id),
      news,
      {
        params: { type: type },
      },
    ),
  deleteNews: (id: number, type: string) =>
    httpClient.delete(newsPath.newsDetail(id), {
      params: { type: type },
    }),
};
