/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { LoadingIcon } from "@assets/Icons";
import { ROUTES } from "@common/Constants";
import { useGetRoutes } from "@common/Hooks";
import { RoleType } from "@common/Types";
import {
  cleanRoute,
  getAccessTokenLocalStorage,
  getUserRole,
} from "@common/Utils";

type Props = {
  notFoundPath: string;
  element: any;
};

export const ProtectedRoute = ({ element: Component, notFoundPath }: Props) => {
  const location = useLocation();

  const userRole = getUserRole();
  const isAuthorized = getAccessTokenLocalStorage();

  const { routes, isLoading: isPermissionLoading } = useGetRoutes(
    userRole as RoleType,
  );

  const curPath = location.pathname;

  if (isPermissionLoading) {
    return <LoadingIcon />; // TODO: add loader component
  }

  if (!isAuthorized) {
    return <Navigate to={ROUTES.MAIN} />;
  }

  const hasPermission = routes?.find((path) => {
    return cleanRoute(path) === cleanRoute(curPath);
  });

  if (!hasPermission) {
    return <Navigate to={notFoundPath} />;
  }

  return Component; // TODO: do this when all routes will be moved <Component />
};
