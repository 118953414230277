import { useField } from "formik";
import { isArray } from "radash";
import React from "react";

import { Params } from "@common/Types";
import { cn } from "@common/Utils";
import { Flexbox, Typography } from "@components/Elements";
import { Switch } from "src/motor_src/components/shared/forms/mantine/switch";

type Props<T> = {
  params: Params<T>[] | Params<T>;
  isEdit: boolean;
  isExtended?: boolean;
};

export const SwitchField = <T,>({ params, isEdit, isExtended }: Props<T>) => {
  const transformedParams = isArray(params) ? params : [params];

  return (
    <>
      {transformedParams.map((limit: Params<T>, index: number) => {
        const { name, field } = limit;
        const [fieldProps, _, helper] = useField(String(field));

        const currentValue = Number(fieldProps.value) ?? 0;
        const { setValue } = helper;

        return (
          <Flexbox
            justifyContent="justify-between"
            bgColor="neutral-50"
            className={cn("w-full py-2 px-4 rounded-lg", {
              "bg-transparent": isExtended,
            })}
            key={`${String(field)}-${index}`}
          >
            <Typography color="neutral-800">{name}</Typography>

            <Switch
              value={currentValue}
              disabled={!isEdit}
              onChange={(value: boolean) => setValue(value ? 1 : 0)}
            />
          </Flexbox>
        );
      })}
    </>
  );
};
