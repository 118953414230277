import { Progress, ProgressProps } from "@mantine/core";
import React from "react";

import { isTargetNavi } from "@assets/Flags";

/**
 * ProgressBar component to display a visual representation of progress.
 *
 * @param {ProgressProps} params - Props for the Progress component from Mantine.
 * @returns {JSX.Element} A Progress component rendered with the provided props.
 */

export const ProgressBar = (params: ProgressProps) => {
  const color = params.color || isTargetNavi ? "#173686" : "#008295";
  return <Progress {...params} color={color} />;
};
