import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { permissionsService } from "@api/services";

/**
 * Получение списка accesses
 */

export const useGetPermissions = (enabled = true) => {
  const {
    isError,
    error,
    data: response,
    ...other
  } = useQuery({
    queryKey: [QUERY_KEYS.ACCESSES],
    queryFn: () => permissionsService.getPermissions(),
    enabled,
  });

  return { isError, error, response, ...other };
};
