import { appConfig } from "@api/config";
import { isTargetNavi } from "@assets/Flags";

/**
 * Сервисы для permission
 */

const src = appConfig.api.dashboard;
const permissionQuery = isTargetNavi
  ? `${src}/dispatcher/permission/`
  : `${src}/admin_navi/permission/`;

export const permissionsPath = {
  permission: permissionQuery,
};
