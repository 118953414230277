import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { usersService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { UserCreateType } from "@common/Types";

type Params = {
  id: string;
  data: UserCreateType;
};

export const useEditUser = () => {
  return useMutation({
    mutationFn: ({ id, data }: Params) => usersService.editUser(id, data),
    onSuccess: ({
      first_name = "",
      last_name = "",
      second_name,
    }: UserCreateType) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DASHBOARD_USERS] });
      const name = `${first_name} ${last_name} ${second_name ?? ""}`;
      toast.success(`Пользователь ${name} успешно изменен`);
    },
  });
};
