import { Select } from "@mantine/core";
import { Field, FormikProps, useField } from "formik";
import { FieldProps } from "formik/dist/Field";
import React from "react";

import { FilterTabType } from "@common/Types";
import { Typography } from "@components/Elements";

type Props = {
  name: string;
  label: string;
  selectLabel: string;
  array: FilterTabType[];
  disabled?: boolean;
  required?: boolean;
};

const CustomSelect = ({
  field,
  form,
  ...props
}: {
  field: FieldProps["field"];
  form: FormikProps<FieldProps>;
  data: FilterTabType[];
}) => {
  return (
    <Select
      name={field.name}
      value={field.value}
      onBlur={field.onBlur}
      onChange={(values) => form.setFieldValue(field.name, values)}
      {...props}
      classNames={{
        input:
          "h-[42px] py-[9px] pl-[14px] w-full placeholder:text-body-1-a p-[0px] placeholder:text-neutral-text-400 border-neutral-bg-300 ring-0 ring-transparent",
        hovered: "bg-[#F5F8FE]",
        item: "flex items-center text-body-1-a h-[45px] mb-[4px] last:mb-0",
        selected: "text-black",
      }}
    />
  );
};

export const FormSelect: React.FC<Props> = ({
  name,
  label,
  selectLabel,
  array,
  disabled,
  required,
}) => {
  const [, meta, helpers] = useField(name);
  const hasError = meta.touched && meta.error;

  return (
    <div className="flex flex-col items-start justify-start space-y-1 w-full relative">
      <label
        htmlFor={name}
        className="text-neutral-text-800 text-caption-b font-medium"
      >
        {label}
        {required && <span className="text-error ml-1"> *</span>}
      </label>
      <div className="relative w-full">
        <Field
          name={name}
          component={CustomSelect}
          data={array}
          placeholder={selectLabel}
          disabled={disabled}
          onBlur={() => helpers.setTouched(true)}
        />
      </div>
      {hasError && (
        <Typography
          variant={"caption-a"}
          color="negative-500"
          className={"mt-[5px]"}
        >
          {meta.error}
        </Typography>
      )}
    </div>
  );
};
