import React from "react";
import { HelmetProvider } from "react-helmet-async";

import { FeatureFlagsProvider } from "@common/Providers";

import { GoogleAnalyticsProvider } from "./GAProvider";

type Props = {
  children: React.ReactNode;
};

export const AppProvidersLayout: React.FC<Props> = ({ children }) => {
  return (
    <HelmetProvider>
      <GoogleAnalyticsProvider>
        <FeatureFlagsProvider>{children}</FeatureFlagsProvider>
      </GoogleAnalyticsProvider>
    </HelmetProvider>
  );
};
