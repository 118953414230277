import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { newsService } from "@api/services";
import { NewsDetailResponse, NewsRequestParams } from "@common/Types";

export const useGetNews = ({ skip, limit, filters }: NewsRequestParams) => {
  return useQuery<NewsDetailResponse>({
    queryKey: [QUERY_KEYS.NEWS, skip, limit, filters],
    queryFn: async () => await newsService.getNews(filters, skip, limit),
  });
};
