import { useUploadImage } from "@api/queries";
import { ImageFiles } from "@common/Types";

interface UploadResult {
  [key: string]: number | null;
}

/**
 * Custom hook for handling image uploads.
 *
 * @returns {{ handleUploadImages: (imageFiles: ImageFiles) => Promise<ImageFiles> }}
 */
export const useUploadImages = () => {
  const { mutateAsync: uploadImage, isPending } = useUploadImage();

  const handleUploadImages = async (imageFiles: ImageFiles) => {
    const uploadPromises = Object.entries(imageFiles).map(
      async ([key, value]) => {
        if (!value) return { [key]: null };
        if (typeof value === "number") return { [key]: value };

        try {
          const response = await uploadImage(value);
          return { [key]: response.id };
        } catch (error) {
          console.error(`Error uploading file ${key}:`, error);
          return { [key]: null };
        }
      },
    );

    try {
      const results = await Promise.all(uploadPromises);

      const uploadedImageIds = results.reduce<UploadResult>(
        (acc, curr) => ({ ...acc, ...curr }),
        {},
      );

      return { ...imageFiles, ...uploadedImageIds };
    } catch (error) {
      console.error("Error uploading images:", error);
      return {};
    }
  };

  return { handleUploadImages, isLoading: isPending };
};
