import { AnyObject } from "chart.js/types/basic";
import qs from "query-string";
import { chain, isEmpty } from "radash";

export const commaSerializer = <T extends Record<string, any>>(filters: T) => {
  return qs.stringify(filters, { arrayFormat: "comma" });
};

export const defaultSerializer = <T extends Record<string, any>>(
  filters: T,
) => {
  return qs.stringify(filters);
};

export const noneSerializer = <T extends Record<string, any>>(filters: T) => {
  return qs.stringify(filters, { arrayFormat: "none" });
};

//  API helpers
export const removeEmptyFilterValues = (
  filters: Record<string, any> | null | undefined,
): Record<string, any> | null | undefined => {
  if (!filters) return filters;

  return Object.fromEntries(
    Object.entries(filters).filter(
      ([_, value]) => typeof value === "boolean" || !isEmpty(value),
    ),
  );
};

export const removeFilterByKeyValue = <T extends AnyObject>(
  keyToBeRemoved: keyof T,
  value: T[keyof T],
  filters: T,
): T => {
  if (!filters) return filters;

  return Object.keys(filters).reduce((acc, currentKey: keyof T) => {
    if (currentKey === keyToBeRemoved && filters[currentKey] === value)
      return acc;

    acc[currentKey] = filters[currentKey];
    return acc;
  }, {} as T);
};

export const removeEmptyFields = <T extends AnyObject>(object: T): T => {
  return Object.keys(object).reduce((acc, key: keyof T) => {
    if (
      object[key] !== undefined &&
      object[key] !== null &&
      object[key] !== ""
    ) {
      acc[key] = object[key];
    }
    return acc;
  }, {} as T);
};

export const buildCommonFiltersValues = <T>(filters: T): Partial<T> => {
  const chained = chain(removeEmptyFilterValues);
  return chained(filters);
};
