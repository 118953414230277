import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { settingsService } from "@api/services/settings";
import { FeesConfigType } from "@common/Types";

export const useUpdateFee = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (fee: FeesConfigType) => settingsService.updateFee(fee),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CITY_FEES],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PARK_FEES],
      });
    },
    retry: 0,
  });
};
