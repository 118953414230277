/**
 * Сервисы для tariffs
 */

import { appConfig } from "@api/config";

const src = appConfig.api.tariffs;
const srcDashboard = appConfig.api.dashboard;

export const tariffsPath = {
  tariffs: `${src}/`,
  tariffsById: `${srcDashboard}/tariffs/`,
};
