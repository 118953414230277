import React from "react";

import { SVGProps } from "@common/Types";

export const TrashIcon = (props: SVGProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="16" fill="#333333" fillOpacity="0.8" />
      <path
        d="M20 12V20.5C20 21.3287 19.3153 22 18.4873 22H13.4873C12.6587 22 12 21.3287 12 20.5V12"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 12.0013H11"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 10.0013H17.3334"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3333 14.668V19.3346"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6666 19.3346V14.668"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
