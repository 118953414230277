import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  show: boolean;
  close: () => void;
  scrollable?: boolean;
  initialFocus?: any;
  outsideClick?: boolean;
  classNames?: string;
  children?: React.ReactNode;
};

export const DialogModal: React.FC<Props> = ({
  children,
  show,
  close,
  scrollable = true,
  initialFocus,
  outsideClick,
  classNames,
}) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className={twMerge(
          `fixed inset-0 z-10`,
          scrollable && "overflow-y-auto",
          classNames,
        )}
        onClose={outsideClick ? () => null : close} //из-за бага с работой datepicker внутри модального окна
        initialFocus={initialFocus}
        static
      >
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
