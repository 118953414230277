import { DatePicker as MantinePicker } from "@mantine/dates";
import { format } from "date-fns";
import { useField, useFormikContext } from "formik";
import React from "react";

import { CalendarIcon } from "@assets/Icons";
import {
  DATE_FORMAT_yyyy_MM_dd,
  DATE_FORMAT_YYYY_MM_DD,
} from "@common/Constants";

type MantineProps = {
  name: string;
  error?: string;
  touched?: boolean;
  defaultDate?: Date | string | null;
  label?: string;
  placeholder?: string;
  required?: boolean;
};

export const DatePicker: React.FC<MantineProps> = ({
  error,
  touched,
  defaultDate,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const hasError = error && touched;
  return (
    <MantinePicker
      required
      zIndex={1000}
      {...props}
      {...field}
      value={field.value ? new Date(field.value) : null}
      classNames={{
        label: "text-xs",
        input:
          "text-body-1-a placeholder-neutral-text-400 border border-neutral-border-300 w-full rounded-[4px] focus:outline-none focus:ring-1 focus:ring-blue-600",
      }}
      locale="ru"
      allowFreeInput={true}
      defaultValue={defaultDate ? new Date(defaultDate) : null}
      clearable={false}
      inputFormat={DATE_FORMAT_YYYY_MM_DD}
      labelFormat={DATE_FORMAT_YYYY_MM_DD}
      icon={<CalendarIcon />}
      onChange={(val: Date) => {
        return (
          val && setFieldValue(field.name, format(val, DATE_FORMAT_yyyy_MM_dd))
        );
      }}
      error={hasError && <p className="text-red-500 text-sm">{error}</p>}
    />
  );
};
