import { Group, SegmentedControl, Title } from "@mantine/core";
import React from "react";

import { SUBSCRIBER_FILTERS } from "@common/Constants";

export type SubscriberFilterType = "all" | "drivers" | "riders";

type Props = {
  filterValue: SubscriberFilterType;
  setFilterValue: (value: SubscriberFilterType) => void;
};

export const SubscribersFilter = ({ filterValue, setFilterValue }: Props) => {
  return (
    <Group spacing={"xs"} direction="column">
      <Title order={5}>Адресат</Title>
      <div className={`inline-flex items-center`}>
        <SegmentedControl
          size={"sm"}
          className={"z-0"}
          value={filterValue}
          onChange={setFilterValue}
          data={SUBSCRIBER_FILTERS}
        />
      </div>
    </Group>
  );
};
