import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { ridersService } from "@api/services";
import { RidersParams, RidersType } from "@common/Types";

export const useGetRiders = ({ limit, skip, filters }: RidersParams) => {
  return useQuery<RidersType>({
    queryKey: [QUERY_KEYS.RIDERS, limit, skip, filters],
    queryFn: async () =>
      await ridersService.getRiders({ limit, skip, filters }),
  });
};
