import React from "react";

import { Typography } from "@components/Elements";
import { parseMarkdown } from "@components/Shared";

type Props = {
  markdown: string;
  question: string;
};

export const FAQAnswer = ({ markdown, question }: Props) => {
  return (
    <div
      className={
        "flex flex-col px-[16px] mt-[18px] mb-[32px] markdown-content text-[14px] leading-[1.42]"
      }
    >
      <Typography
        color={"neutral-800"}
        className={"font-semibold pb-[16px]"}
        variant={"heading-2-a"}
      >
        {question}
      </Typography>
      {parseMarkdown(markdown)}
    </div>
  );
};
