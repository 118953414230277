/**
 * Сервисы для applications
 */

import { appConfig } from "@api/config";

const src = appConfig.api.dashboard;
const srcDrivers = appConfig.api.drivers;

export const applicationsPaths = {
  driver: `${src}/drivers/applications/`,
  driverDetail: (id: string) => `${src}/drivers/applications/${id}`,
  driverApprove: (id: string) => `${src}/drivers/applications/${id}/approve/`,
  driverDecline: (id: string) => `${src}/drivers/applications/${id}/decline/`,
  landingDriverApplication: `${srcDrivers}/support-applications/`,
  landingDriverApplicationApprove: (id: string) =>
    `${srcDrivers}/support-applications/${id}/read`,
};
